import { Injectable } from '@angular/core';
import { ParamServicesService } from './param-services.service';

@Injectable({
  providedIn: 'root'
})
export class PwaService {
  deferredPrompt = undefined;
  backbutton = false; 
  existDistributorSignUp = undefined;
  
  constructor(
    private paramSv: ParamServicesService
  ) {
    const obs = this.paramSv.existDistributorSignUpObs.subscribe((item) => {
      this.existDistributorSignUp = item;
      obs.unsubscribe();
    })
  }

  pwa() {
    window.addEventListener('beforeinstallprompt', event => {
      // Prevent Chrome <= 67 from automatically showing the prompt
      event.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = event;
      // Update the install UI to notify the user app can be installed
    });

    window.addEventListener('appinstalled', ev => {
      this.backbutton = true;
    })
  }

}
