import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-gift-card',
  templateUrl: './gift-card.component.html',
  styleUrls: ['./gift-card.component.scss'],
})
export class GiftCardComponent implements OnInit {
  @Input() giftCard: any = undefined;
  public contador = 0;

  constructor() { }

  ngOnInit() {}

  countCard(count: any){
    this.giftCard.counter = count;
  }
}
