import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InsertDetailDirective } from './insert-detail.directive';
import { SwipeCatalogueDirective } from './swipe-catalogue.directive';

const declarations = [
  InsertDetailDirective,
  SwipeCatalogueDirective
]

@NgModule({
  declarations,
  imports: [
    CommonModule
  ],
  exports: declarations,
})
export class DirectivesModule { }
