import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { BreadcrumbsService } from 'src/app/services/breadcrumbs.service';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnInit {
  @Input() category: string = '';
  @Output() emitSectionItem = new EventEmitter();

  constructor(
    public breadCrumbSv: BreadcrumbsService,
    private navCtrl: NavController,
  ) { }

  ngOnInit() {
   
  }

  trackByItems(index, item){
    return item.id
  }

  setSection(item){
    const location = window.location.pathname;
    const lastElm = this.breadCrumbSv.template[this.breadCrumbSv.template.length - 1];
    if(!item.id && !item.parentId){
      this.breadCrumbSv.template = [{...this.breadCrumbSv.name}];
      this.navCtrl.navigateForward(location);
    }else if(item.id && !item.parentId && item.name !== lastElm.name){
      this.navCtrl.navigateForward(location, {queryParams: {category: this.category}});
      this.breadCrumbSv.template.pop();
    }
    // this.emitSectionItem.emit(item);
  }
}
