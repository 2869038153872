import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { LoginService } from '../services/login.service';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class VerifyGuard implements CanActivate {
  constructor(private loginSv: LoginService, private navCtrl: NavController) {}

  async canActivate(): Promise<any> {
    await this.loginSv.getTokenUser();
    if (this.loginSv.user && this.loginSv.user.isVerifiedEmail) {
      this.navCtrl.navigateRoot('home');
      return false;
    } else if (this.loginSv.user && !this.loginSv.user.isVerifiedEmail) {
      return true;
    }
  }
}
