import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appAddAnim]'
})
export class AddAnimDirective {
  private _observer: IntersectionObserver = undefined;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.createObserver();
  }

  private createObserver() {

    const options: IntersectionObserverInit = {
      root: null,
      rootMargin: "0px",
      threshold: 0.2
    };

    const handleIntersect = (entries: any[]) => {
      for (const entry of entries) {
        if(entry.isIntersecting){
          this.renderer.addClass(this.el.nativeElement, 'animate__fadeInLeft');
          this._observer.disconnect();
        }else{
          this.renderer.removeClass(this.el.nativeElement, 'animate__fadeInLeft');
        }
      }
    }

    setTimeout(() => {
      this._observer = new IntersectionObserver(handleIntersect, options);
      this._observer.observe(this.el.nativeElement);
    }, 1000)
    
  }
}
