import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcPercentPerLevel',
})
export class CalcPercentPerLevelPipe implements PipeTransform {
  transform(
    value: number,
    levels: any[],
    nivel,
    incentive: any
  ): number | string {
    const level = levels.find((l) => l.level === nivel);
    const percentLevel = (value * level.percenger) / 100;
    if (incentive.percentage) {
      const percentReedem = (percentLevel * incentive.percentage) / 100;
      return percentReedem;
    } else {
      return incentive.textPercentage;
    }
  }
}
