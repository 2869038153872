import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-instagram',
  templateUrl: './instagram.component.html',
  styleUrls: ['./instagram.component.scss'],
})
export class InstagramComponent implements OnInit {
  @Input() data: any = undefined;
  
  constructor() { }

  ngOnInit() {}

}
