import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UiServices } from 'src/app/services/ui-services';
import { Subscription } from 'rxjs';
import { IonSlides } from '@ionic/angular';
import { LoginService } from 'src/app/services/login.service';
import { BalanceService } from 'src/app/services/balance.service';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-card-productos',
  templateUrl: './card-productos.component.html',
  styleUrls: ['./card-productos.component.scss'],
})
export class CardProductosComponent implements OnInit, OnDestroy {
  @Input() product: any = undefined;
  @ViewChild('slideImages') set loadSlides(element: IonSlides){
    this.slideImages = element;
    if(this.images.length){
      this.slideImages.lockSwipes(true);
    }
  };
  public slideImages: IonSlides = undefined;
  public typeCard = '2';
  public carritoIcon: string = './assets/icon/union17.svg';
  public url = environment.url;
  public urlMarketplace = `${window.location.pathname}/products`;
  public urlImage = environment.uriProduct;
  public thumbnailImage: string = '';
  public cantidad: number = 0;
  public priceProduct = 0;
  public subscriber: Subscription = new Subscription();
  public currency: any;
  public currencyName = 'USD';
  public images: any[] = [];
  public pager = '';
  private _utilityPricePromo: any;
  private _utliityPrice: any;
  public utility: any;
  public showUtility = false;
  public price: any = '';
  public priceWSale: any = '';
  public promotionalPrice: any = '';
  public promotionalPriceWSale: any = '';
  public interval: any;
  public slideOpts = {
    speed: 400,
    effect: 'fade'
  }
  private contador = 1;
  public isNewer = false;
  public progress = 0;
  public progressTime = undefined;
  public promoTxt = false;
  public type = undefined;

  constructor(
    private uiSv: UiServices,
    public loginSv: LoginService,
    private balanceSv: BalanceService,
    public paramSv: ParamServicesService,
    private translateSv: TranslateService
  ) {}


  ngOnInit(): void {
    try{
      if('inscriptionPrice' in this.product){
        this.type = 'subscription'
      }else if('duration' in this.product){
        this.type = 'service';
      }else if(this.product.type === 'redeemable' || this.product.type === 'redeemables'){
        this.type = 'redeemable';
      }else if(this.product.type === 'achievements'){
        this.type = 'achievement';
      }else{
        this.type = 'product';
      }
      this.price = this.product.price;
      this.promotionalPriceWSale = this.product.promotionalPriceWSale;
      this.promotionalPrice = this.product.promotionalPrice;
      this.priceWSale = this.product.priceWSale;
      const endPromotionalDate = new Date(this.product.endPromotional);
      const initPromotionalDate = new Date(this.product.initPromotional);
      const actualDate = new Date();

      if(actualDate >= initPromotionalDate && actualDate <= endPromotionalDate){
        this.promoTxt = true
      }

      if(this.product?.media){
        this.images = this.product?.media.filter(item => !item.isVideo);
      }

      if(this.balanceSv.currency){
        this.currency = this.balanceSv.currency;
        this.setCurrencyChange(this.balanceSv.currency);
      }
      
      this.subscriber.add(this.balanceSv.currencySource.subscribe((currency) => {
        this.setCurrencyChange(currency);
      }));
      
    }catch(err){
      console.error(err);
    }
  }

  trackByItems(index, item){
    return item.id
  }
   
  // async seeMore(){
  //   console.log(this.product);
    
  //   if(this.product.duration){
  //     this.navCtrl.navigateForward(`meeting/${this.product.id}`);
  //   }else if(
  //     this.product.inscriptionPrice 
  //     || this.product.inscriptionPrice === 0 
  //     || this.product.inscriptionPrice === null
  //   ){
  //     const queryParams = {
  //       sbp: 1
  //     }
  //     this.navCtrl.navigateForward(`product-detail/${this.product.id}`, {queryParams});
  //   }else if(this.product.type === 'redeemable'){
  //     this.navCtrl.navigateForward(`product-detail/${this.product.id}`,
  //       {
  //         queryParams: {
  //           type: 'reedem'
  //         }
  //       }
  //     );
  //   }else{
  //     this.navCtrl.navigateForward(`product-detail/${this.product.id}`);
  //   }
  // }

  async countProducts(op){
    if(this.product?.existence === null || (this.cantidad < this.product?.existence) || op === 'rest'){
    }else{
      const translate = this.translateSv.instant('NO_HAY_CANTIDAD_DISPONIBLE');
      this.uiSv.showToast(translate);
    }
  }

  changeImage(){
    if(this.images?.length > 1){
      this.pager = 'md';
      if(this.interval){
        clearInterval(this.interval);
      }
      this.startProgress();
      this.interval = setInterval(async () => {
        await this.slideImages.lockSwipes(false);
        if(this.contador === this.images.length){
          clearInterval(this.progressTime);
        }else{
          this.startProgress();
          await this.slideImages.slideNext(500);
          this.contador++;
        }
        this.progress = 0;
        await this.slideImages.lockSwipes(true);
      }, 3300);
    }
  }

  startProgress(){
    try{
      let contador = 0;
      if(this.progressTime){
        clearInterval(this.progressTime);
      }
      this.progressTime = setInterval(() => {
        contador++;
        this.progress = contador / 100;
        if(contador > 100){
          if(this.progressTime){
            clearInterval(this.progressTime);
          }
        }
      }, 30);
    }catch(err){
      console.error(err);
      clearInterval(this.progressTime);
    }
  }

  async stopInterval(){
    if(this.images.length){
      await this.slideImages.lockSwipes(false);
      await this.slideImages.slideTo(0, 500);
      await this.slideImages.lockSwipes(true);
      this.contador = 1;
      this.progress = 0;
      clearInterval(this.interval);
      clearInterval(this.progressTime);
    }
  }

  async setCurrencyChange(currency){
    this.currency = currency;
    // const { 
    //   price,
    //   promotionalPrice,
    //   priceWSale,
    //   promotionalPriceWSale,
    //   currencyName ,
    // } = await this.balanceSv.setCurrency(this.product, currency);
    // this.price = Number(price) || 0;
    // this.promotionalPrice = Number(promotionalPrice) || 0;
    // this.priceWSale = Number(priceWSale) || 0;
    // this.promotionalPriceWSale = Number(promotionalPriceWSale) || 0;
    this.currencyName = currency?.symbol || 'USD';
    this._utliityPrice = this.price - (this.promotionalPriceWSale || this.priceWSale);
    this._utilityPricePromo = this.promotionalPrice - this.promotionalPriceWSale;
    if(this._utilityPricePromo > 0){
      this.utility = this._utilityPricePromo;
    }else if(this._utliityPrice > 0){
      this.utility = this._utliityPrice;
    }

    const rolId = this.loginSv.user?.rolId;
    const dist = (rolId === 7 || (this.paramSv.existDistributorSignUp && rolId === 3));
    if(
      dist 
      && 
      (
        (this.product.price && this.product.priceWSale && (this.product.price > this.product.priceWSale)) 
        ||
        ( 
          this.product.promotionalPrice && this.product.promotionalPriceWSale 
          && (this.product.promotionalPrice > this.product.promotionalPriceWSale) 
        )
      )
    ){
      this.showUtility = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriber.unsubscribe();
  }

  doDiference(product){
    const promo: any = product?.promotionalPrice - product?.promotionalPriceWSale;
    const price: any = product?.price - product?.priceWSale;
    return (promo > 0 ) ? promo : price > 0 ? price : null;
  }
}
