import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-home-video',
  templateUrl: './home-video.component.html',
  styleUrls: ['./home-video.component.scss'],
})
export class HomeVideoComponent implements OnInit {
  @ViewChild('embed', { static: true }) embed: ElementRef<HTMLDivElement>
  @Input() data = undefined;
  public homeVideo = { embed: '', btnRoute: '', btnText: '' };

  constructor(
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    const data = JSON.parse(this.data.video);
    this.homeVideo = data;
    this.embed.nativeElement.innerHTML = this.homeVideo.embed;
  }

  open(){
    const route = this.homeVideo.btnRoute;
    const type = !!route.includes('https');
    if(type){
      window.open(route, '_blank');
    }else{
      this.navCtrl.navigateForward(route);
    }
    return true;
  }
}
