import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { LoginService } from './login.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  public url: string = environment.url;
  public token: string;
  public loadingIndicatorSrc = new Subject();
  public loadingIndicatorObs = this.loadingIndicatorSrc.asObservable();
  private _domainId = environment.domainId;

  constructor(private http: HttpClient, private loginSv: LoginService) {}

  getToken() {
    return new Promise((resolve) => {
      setTimeout(async () => {
        this.token = await this.loginSv.getTokenUser();
        resolve(this.token);
      });
    });
  }

  catalogues(
    domainId,
    catalogue,
    pag,
    search?,
    category = '',
    categoryChild = '',
    subscription = false
  ) {
    return new Promise(async (resolve, reject) => {
      const params = {
        domainId,
        catalogue,
        pag,
        search,
        category,
        categoryChild,
        subscription: subscription ? '1' : 0,
      };
      const res = await this.searchProduct(params);
      resolve(res);
      // this.http.get(`${this.url}/client/products/search/${domainId}/${catalogue}/${pag}/${search}/${category}/${categoryChild}`).subscribe( res => {
      //   resolve(res);
      // }, err => {
      //   reject(err);
      // })
    });
  }

  //Administrador

  getCatalogue(domainId, catalogueId, pag) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(
          `${this.url}/client/cataloguePage/index/${domainId}/${catalogueId}/${pag}`
        )
        .subscribe(
          (res: any) => {
            resolve(res.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  getParam(name, domainId) {
    return new Promise<any>((resolve, reject) => {
      this.http
        .get(`${this.url}/client/parameter/showName/${name}/${domainId}`)
        .subscribe(
          (res: any) => {
            resolve(res.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }
  itemAction(body, URI, param) {
    return new Promise<any>(async (resolve, reject) => {
      await this.getToken();
      const headers = new HttpHeaders({
        Authorization: this.token,
      });
      this.http
        .post(`${this.url}/${URI}/${param}`, body, { headers })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  async itemIndex(
    URI,
    page: any = '',
    params?,
    index: string | number = 'index/'
  ): Promise<any> {
    await this.getToken();
    const headers = new HttpHeaders({
      Authorization: this.token,
    });

    if (!this.loginSv.user && URI === 'attentionSchedule') {
      if (!page) page = `//${this._domainId}`;
      else page += this._domainId;
    }

    return new Promise<any[]>((resolve, reject) => {
      this.http
        .get(`${this.url}/${URI}/${index}${page}`, {
          headers,
          params,
        })
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          async (err) => {
            console.error(err);
            if (
              err?.error?.errors &&
              err?.error?.errors[0]?.message ===
                'E_UNAUTHORIZED_ACCESS: Unauthorized access' &&
              URI !== 'notification'
            ) {
              await this.loginSv.logout();
            }
            reject(err);
          }
        );
    });
  }
  async downloadImage(url): Promise<any> {
    await this.getToken();
    const headers = new HttpHeaders({
      Authorization: this.token,
    });

    return new Promise<any[]>((resolve, reject) => {
      this.http
        .get(url, {
          headers,
        })
        .subscribe(
          (res: any) => {
            resolve(res);
          },
          async (err) => {
            console.error(err);
            if (
              err?.error?.errors &&
              err?.error?.errors[0]?.message ===
                'E_UNAUTHORIZED_ACCESS: Unauthorized access'
            ) {
              await this.loginSv.logout();
            }
            reject(err);
          }
        );
    });
  }

  deleteItem(body, uri) {
    const headers = new HttpHeaders({
      Authorization: this.token,
    });

    return new Promise<any[]>((resolve, reject) => {
      this.http.post(`${this.url}/${uri}/destroy`, body, { headers }).subscribe(
        (res: any[]) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  /////////////////////////////

  getHome() {
    return new Promise((resolve, reject) => {
      this.http.get(`${this.url}/client/home`).subscribe(
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  // Obtener catalogos

  getCatalogues(type, domainId) {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.url}/client/catalogue/${type}/${domainId}`)
        .subscribe(
          (res: any) => {
            resolve(res.data);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  // Mostrar product

  async getProduct(id, type = 'product') {
    await this.getToken();
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: this.token,
      });
      this.http.get(`${this.url}/${type}/show/${id}`, { headers }).subscribe(
        (res: any) => {
          resolve(res.data);
        },
        (err) => {
          reject(err);
        }
      );
    });
  }

  async searchWithKeywords({ domainId, search }) {
    await this.getToken();
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: this.token,
      });
      this.http
        .get(`${this.url}/client/keywords/index`, {
          params: { domainId, search },
          headers,
        })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  async searchProduct(params) {
    await this.getToken();
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders({
        Authorization: this.token,
      });
      this.http
        .get(`${this.url}/client/products/search`, { headers, params })
        .subscribe(
          (res) => {
            resolve(res);
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  async doTransaction(data, type, from?, to?) {
    try {
      data.set('type', type);
      data.set('from', from);
      data.set('to', to);
      const res: any = await this.itemAction(data, 'transaction', 'store');
      return res;
    } catch (err) {
      console.error(err);
    }
  }

  // Categorias

  // createCategorie(body, action){
  //   const headers = new HttpHeaders({
  //     'Authorization': this.token
  //   })

  //   return new Promise((resolve, reject) => {
  //     this.http.post(`${this.url}/category/${action}`, body, { headers }).subscribe((res: any) => {
  //       resolve(res.data);
  //     }, err => {
  //       reject(err)
  //     })
  //   })
  // }

  // getCategories(){
  //   return new Promise<any>((resolve, reject) => {
  //     console.log('hola');

  //     this.http.get(`${this.url}/category/index`).subscribe((res: any) => {
  //       resolve(res.data);
  //     }, err => {
  //       reject(err)
  //     })
  //   })
  // }

  deleteImageBanner(body, uri, type) {
    const headers = new HttpHeaders({
      Authorization: this.token,
    });
    let action = type === 'banner' ? 'destroyFile' : 'destroy';
    if (type === 'catalogue') {
      action = 'files/destroy';
    }
    return new Promise<any[]>((resolve, reject) => {
      this.http
        .post(`${this.url}/${uri}/${action}`, body, { headers })
        .subscribe(
          async (res: any[]) => {
            resolve(res);
          },
          async (err) => {
            reject(err);
          }
        );
    });
  }
}
