import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-catalogue-products',
  templateUrl: './catalogue-products.component.html',
  styleUrls: ['./catalogue-products.component.scss'],
})
export class CatalogueProductsComponent implements OnInit {
  @Input() catalogueSection: any[];
  @Input() data: any;

  constructor(
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.catalogueSection = this.catalogueSection.filter(item => item.product?.length);
  }

  trackByItems(index, item){
    return item.id
  }

  goCatalogue(id){
    this.navCtrl.navigateForward(`/${id}`);
  }
}
