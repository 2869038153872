import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-branch',
  templateUrl: './branch.component.html',
  styleUrls: ['./branch.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})

export class BranchComponent implements OnInit, AfterViewInit {
  @ViewChild('brancSlides', { static: true }) branchSlides: IonSlides;
  @Input() data: any;
  // resolution = window.matchMedia('(max-width: 578px)').matches;
  private _reachedEnd = false;
  public isStart = true;

  set reachedEnd(value){
    this._reachedEnd = value;
  }

  get reachedEnd(){
    return this._reachedEnd;
  }

  constructor() { }

  async ngOnInit() {
    this.data.branchContact = this.data.branchContact.filter(item => item.maps && item.name);
  }

  ngAfterViewInit(): void {
    setTimeout(async() => {
      this.reachedEnd = await this.branchSlides.isEnd();
    })
  }

  // ngAfterViewInit(): void {
   
  //   // this.data.branchContact.map((item, index) =>  {
  //   //   this.getMap(index, item.maps);
  //   // })
  // }

  getMap(index, maps){
    // if(!maps){
    //   return
    // }
    // const element = document.querySelector(`.map${index}`);
    // const iframe = document.createElement('iframe');
    // iframe.setAttribute('src', maps);
    // iframe.setAttribute('class', 'iframeMap');
    // iframe.setAttribute('width', '100%');
    // iframe.setAttribute('height', this.resolution ? '300' : '450');
    // iframe.setAttribute('style', 'border:0; border-radius: 8px');
    // iframe.setAttribute('allowfullscreen', '');
    // iframe.setAttribute('loading', 'lazy');
    // iframe.setAttribute('referrerpolicy', 'no-referrer-when-downgrade');
    // element.appendChild(iframe);
  }

  async moveTo(direction){
    await direction === 'next' ? this.branchSlides.slideNext() : this.branchSlides.slidePrev();
  }

  trackByItems(index, item){
    return item.id
  }

  async changedSlide(ev){
    this.reachedEnd = ev.el.dom7ElementDataStorage.swiper.isEnd;
    if(!this.reachedEnd || this.reachedEnd){
      this.isStart = ev.el.dom7ElementDataStorage.swiper.isBeginning;
    }
  }
}
