import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ParamServicesService } from '../services/param-services.service';
import { LoginService } from '../services/login.service';

@Injectable({
  providedIn: 'root'
})

@Pipe({
  name: 'fixUrl'
})
export class FixUrlPipe implements PipeTransform {
  private _logo: string;

  constructor(
    private loginSv: LoginService,
    private paramSv: ParamServicesService
  ){}

  transform(
    file: string, 
    type: string, 
    slice?: boolean, 
    logo: boolean = true, 
    webp: boolean = false, 
    thumb: boolean = false,
    username: string = ''
  ): string {
    
    this.setLogo(username, thumb);
    const originalFileName = file;
    file = file ? file.toLocaleLowerCase() : undefined;
    if(slice){
      type = type.slice(0, type.length - 1);
    }
    
    if(
      file
      && !file.includes('.jpg') 
      && !file.includes('.jpeg') 
      && !file.includes('.png') 
      && !file.includes('.webp') 
      && !file.includes('.gif') 
      && !file.includes('.pdf') 
      && !file.includes('.svg') 
      && !file.includes('.bmp')
      && !file.includes('.jfif')
    ){
      return this._logo;
    }else if(!file && logo){
      return this._logo;
    }
    thumb && (type = `${type}/thumb`)
    if (webp) {
      const indexFileName = originalFileName.lastIndexOf('.')
      let fileName = originalFileName.slice(0, indexFileName)
      const uri = `${environment.url}/file/${type}/${fileName}.webp`;
      return uri;
    }

    const uri = `${environment.url}/file/${type}/${originalFileName}`;

    return uri;
  }

  setLogo(username, thumb){
    let avatar = undefined;
    if(!thumb){
      const userName = username || this.loginSv?.user?.name?.replace(/ /g,'+');
      avatar = `https://ui-avatars.com/api/?size=512&?format=png&name=${userName}.png`;
    }else{
      const logoHeader = this.paramSv.setLogo('Login');
      avatar = logoHeader || environment.defaultImg;
    }
    
    this._logo = avatar;

    // return new Promise<string>(resolve => {
    //   setTimeout(() => {
    //     resolve('');
    //   })
    // })
    // const logoHeader = this.paramSv.setLogo('Login');
  }

}
