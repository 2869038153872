import { Component, Input, OnInit } from '@angular/core';
import { InteractiveProductSelectorComponent } from 'src/app/pages/admin/componentsAdmin/interactive-product-selector/interactive-product-selector.component';
import { HttpService } from 'src/app/services/http.service';
import { InteractiveService } from 'src/app/services/interactive.service';
import { UiServices } from 'src/app/services/ui-services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-interactive-catalogue',
  templateUrl: './interactive-catalogue.component.html',
  styleUrls: ['./interactive-catalogue.component.scss'],
})
export class InteractiveCatalogueComponent implements OnInit {
  @Input() uri: any = undefined;
  @Input() action: any = true;
  @Input() code: any = true;
  @Input() set savedForm(value) {
    if (value) {
      this.catalogue = value;
      if (!value.products) this.catalogue = value;
      else {
        value.products.forEach((x) => {
          x.coords = JSON.parse(x.coords);
        });
        this.catalogue.interactiveProduct = value.products;
        delete this.catalogue.products;
      }
    }
  }

  public catalogue: any = {
    image: environment.defaultImg,
    interactiveProduct: [],
    name: '',
    id: undefined,
  };

  constructor(
    private uiSv: UiServices,
    private httpSv: HttpService,
    private interactiveSv: InteractiveService
  ) {}

  ngOnInit() {}

  async pushBtn(ev) {
    if (ev.target.tagName === 'IMG') {
      const width = ev.target.offsetWidth;
      const height = ev.target.offsetHeight;
      const minusPx = window.matchMedia('(max-width: 1024px)').matches
        ? 14
        : 24;
      const x = ((ev.offsetX - minusPx) * 100) / width;
      const y = ((ev.offsetY - minusPx) * 100) / height;
      const { data } = await this.uiSv.showModal(
        InteractiveProductSelectorComponent,
        {},
        '',
        ev
      );
      if (data) {
        const obj = {
          coords: { x: `${x}%`, y: `${y}%` },
          product: data,
        };
        this.catalogue.interactiveProduct.push(obj);
      }
    }
  }

  async save() {
    try {
      await this.uiSv.showLoading();
      await this.httpSv.itemAction(
        this.catalogue,
        'interactiveImages',
        'update'
      );
      await this.uiSv.loading.dismiss();
      await this.uiSv.showToast('Guardado éxitosamente');
    } catch (err) {
      console.error(err);
      await this.uiSv.loading.dismiss();
    }
  }

  async remove(index) {
    try {
      await this.uiSv.showLoading();
      const product = this.catalogue.interactiveProduct[index];
      await this.httpSv.itemAction(
        { id: product.id },
        'interactiveImages/product',
        'destroy'
      );
      await this.uiSv.loading.dismiss();
      this.catalogue.interactiveProduct.splice(index, 1);
    } catch (err) {
      console.error(err);
    }
  }

  add(btn) {
    if (btn.product.quantity) btn.product.quantity += 1;
    else btn.product.quantity = 1;
    this.interactiveSv.addCart(btn.product);
  }
}
