import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { InfiniteScroll } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-infinite-scroll',
  templateUrl: './infinite-scroll.component.html',
  styleUrls: ['./infinite-scroll.component.scss'],
})
export class InfiniteScrollComponent implements OnInit {
  @Input() data: any[] = [];
  @Input() text: string = '';
  @Output() loadData = new EventEmitter<InfiniteScroll>();
  @ViewChild('virtualScroll') set virtualScroll(el: ElementRef<HTMLDivElement>){
    if(el){
      this._el = el.nativeElement;
      this.createObserver();
    }
  }
  public visible = true;
  private _el = undefined;
  private _observer: IntersectionObserver = undefined;

  constructor(
  ) { }

  ngOnInit() {}

  private createObserver() {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5
    };
    const handleIntersect = (entries: any[]) => {
      const entry: IntersectionObserverEntry = entries[0];
      if(entry.isIntersecting){
        const data: InfiniteScroll = { 
          disconnect: () => this.disconnect(), 
          observe: () => this.observe(),
          complete: () => this.complete(),
          setVisible: () => this.setVisible()
        };
        this.loadData.emit(data);
        this._observer.disconnect();
      }
    }

    this._observer = new IntersectionObserver(handleIntersect, options);
    this._observer.observe(this._el);
  }

  private disconnect(){
    this._observer.disconnect() 
  }

  private observe(){
    this._observer.observe(this._el)
  }

  private complete(){
    return this.visible = false
  }

  private setVisible(){
    return this.visible = true;
  }

}
