import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {
  private _template: any[] = [];
  public name = undefined;
  public emitBreadCrumb = new EventEmitter();

  set template(value){
    this._template = value;
  }
  
  get template(){
    return this._template;
  }

  constructor() { }


  pushCrumb(id, name, isChild, haveId){
    const obj: any = {
      name: name
    }
    if(haveId){
      const existChild = !!this.template.find(item => item.parentId);
      if(existChild) this.template.pop();
      isChild ? obj.parentId = id : obj.id = id;
    }else{
      this.name = obj;
    }
    if(!this.template.find(item => item.name === name)){
      this.template.push(obj);
    }
    // this.emitBreadCrumb.emit(this.template);
  }
}
