import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { IonSlides } from '@ionic/angular';

@Component({
  selector: 'app-detailed-gallery',
  templateUrl: './detailed-gallery.component.html',
  styleUrls: ['./detailed-gallery.component.scss'],
})
export class DetailedGalleryComponent implements OnInit {
  @ViewChild('slideGallery') ionSlide: IonSlides;
  @Input() galleries: any = undefined
  @Input() data: any = undefined
  public reachedEnd = false;
  public isStart = true;
  public slidesOpt = {
    breakpoints: {
      320: {
        slidesPerView: 1.1
      },
      620: {
        slidesPerView: 1
      }
    }
  }

  constructor() { }

  ngOnInit() {
    
  }

  async changedSlide(ev){
    this.reachedEnd = ev.el.dom7ElementDataStorage.swiper.isEnd;
    if(!this.reachedEnd || this.reachedEnd){
      this.isStart = ev.el.dom7ElementDataStorage.swiper.isBeginning;
    }
  }

  trackByItems(index, item){
    return item.id
  }

  slideTo(direction: string) {
    direction == 'next' ? this.ionSlide.slideNext() : this.ionSlide.slidePrev();
  }
}
