import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss'],
})
export class HistoryComponent implements OnInit {
  @Input() showLoading: boolean;
  @Input() statusAccountWallet = true;
  public image = '/assets/admin/defaultImage.png';
  public page = 1;
  public type = '';
  public transactions: any[] = [];
  private totalPages: number;
  
  constructor(
    private httpSv: HttpService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.getHistory();  
  }

  async goTo(){
    await this.navCtrl.navigateForward('wallet/history');
  }

  async getHistory(){
    try{
      let res: any;
      if(this.statusAccountWallet){
        res = await this.httpSv.itemIndex('transaction', `${this.page}/${this.type}`);
      }else{
        res = await this.httpSv.itemIndex('payment', `${this.page}`);
      }
      const data = res.data;
      this.totalPages = data.meta.last_page;
      this.transactions.push(...data.data);
    }catch(err){
      console.error(err);
    }
  }

  loadData(event) {
    setTimeout(() => {
      event.target.complete();
      this.page++;
      this.getHistory();
      if (this.totalPages === this.page) {
        event.target.disabled = true;
      }
    }, 500);
  }
}
