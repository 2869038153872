import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { IonSelect } from '@ionic/angular';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-galleries-open',
  templateUrl: './galleries-open.component.html',
  styleUrls: ['./galleries-open.component.scss'],
})
export class GalleriesOpenComponent implements OnInit {
  @Input() galleries: any;
  @ViewChild('descriptionEl', {static: true}) descriptionEl: ElementRef<HTMLDivElement>;

  constructor(
    private paramSv: ParamServicesService
  ) { }

  ngOnInit() {
    if(this.galleries){
      this.descriptionEl.nativeElement.innerHTML = this.galleries?.description;
    }
  }

  open(ev){
    const numWpp = ev.detail.value;
    window.open(`https://wa.me/${numWpp}?text=${this.paramSv.msgGalleryWpp} ${this.galleries?.name}`, '_blank');
  }

  openBracn(select: IonSelect){
    if(this.galleries?.branchContactId.length === 1){
      this.open({detail: {value: this.galleries?.branchContactId[0].number}});
      return
    }
    select.open();
  }

}
