import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';

@Component({
  selector: 'app-slider-principal',
  templateUrl: './slider-principal.component.html',
  styleUrls: ['./slider-principal.component.scss'],
})
export class SliderPrincipalComponent implements OnInit {
  @ViewChild('slider', {static: true}) slider: IonSlides;
  @Input() data: any;
  @Input() slidesHome;
  @Input() typeImg: string = 'banner';

  urlAssets: string = './assets/slideHome';
  slideOpt = {
    loop: true,
    speed: 400,
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
    },
  };

  constructor(
    private navCtrl: NavController
  ) { }

  async ngOnInit() {
    
    if(this.slidesHome.length > 1){
      await this.slider.startAutoplay();
    }else{
      await this.slider.lockSwipes(true);
    }
  }

  slideTo(pos?){
    (pos === 'next') ? this.slider.slideNext() : this.slider.slidePrev();
  }

  goTo(url){
    if(!url){
      return;
    }
    const type = !!url.includes('https');
    if(type){
      window.open(url, '_blank');
    }else{
      this.navCtrl.navigateForward(url);
    }
  }

}
