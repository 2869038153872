import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcPercentComission',
})
export class CalcPercentComissionPipe implements PipeTransform {
  transform(data: any): number {
    return Math.round(
      (data.commission * 100) / (data.promotionalPrice || data.price)
    );
  }
}
