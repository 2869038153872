import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from 'src/app/services/login.service';
import { UiServices } from 'src/app/services/ui-services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgottenPasswordComponent implements OnInit {
  @Output() slideTo = new EventEmitter();

  public forgottenForm: FormGroup
  domainId = environment.domainId;

  constructor(
    private fb: FormBuilder,
    private loginSv: LoginService,
    private modalController: ModalController,
    private uiSv: UiServices,
    private trSv: TranslateService
  ) { }

  ngOnInit() {
    this.forgottenForm = this.fb.group({
      email: ['', [ Validators.required, Validators.minLength(6) ]
      ]
    })
  }
  // Validators.pattern(/^[\w\.g]+@+[\w]+[.]+[\D]{2,10}$/)
  back(){
    this.slideTo.emit();
  }

  async reset(){
    try{
      this.forgottenForm.value.domainId = this.domainId;
      await this.uiSv.showLoading();
      await this.loginSv.recoveryPassword(this.forgottenForm.value);
      this.closeModal();
      const tr = this.trSv.instant('TE_ENVIAMOS_UN_CORREO_DE_RECUPERACION'); 
      await this.uiSv.showToast(tr);
      await this.uiSv.loading.dismiss();
    }catch(err){
      console.error(err);
      const tr = this.trSv.instant('HA_OCURRIDO_UN_ERROR');
      await this.uiSv.showToast(tr);
      await this.uiSv.loading.dismiss();
    }
  }

  closeModal(){
    this.modalController.dismiss();
  }
}
