import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-galleries',
  templateUrl: './galleries.component.html',
  styleUrls: ['./galleries.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GalleriesComponent implements OnInit {
  @Input() gallery;
  @Output() showGallery = new EventEmitter();

  constructor() { 
  }

  ngOnInit() {
  }

  showMore(){
    this.showGallery.emit(this.gallery);
  }

}
