import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terms-component',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
})
export class TermsComponent implements OnInit {
  private domainId = environment.domainId;
  public terms: any[] = [];

  constructor(
    private httpSv: HttpService
  ) { }

  async ngOnInit() {
    try{
      const res: any = await this.httpSv.itemIndex('term', `//${this.domainId}`);
      this.terms = res.data.data;
      setTimeout(() => {
        this.terms.map((item, index) => {
          const element = document.querySelector(`.term${index}`);
          element.innerHTML = item.description;
        })
      })
    }catch(err){
      console.error(err);
    }
  }

}
