import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-name-branch',
  templateUrl: './name-branch.component.html',
  styleUrls: ['./name-branch.component.scss'],
})
export class NameBranchComponent implements OnInit {
  @Input() data: any;

  public totalElements: number = undefined;

  constructor() { }

  ngOnInit() {
    this.totalElements = Object.entries(this.data).length;
  }

}
