import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { MenuComponent } from './menu/menu.component';
import { HeadercomponentComponent } from './headercomponent/headercomponent.component';
import { CardProductosComponent } from './card-productos/card-productos.component';
import { SlidesCardHomeComponent } from './slides-card-home/slides-card-home.component';
import { PipeModule } from '../pipes/pipe-module.module';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PopoverTagsComponent } from './popover-tags/popover-tags.component';
import { ShowProductClientComponent } from './show-product-client/show-product-client.component';
import { PagerComponent } from './pager/pager.component';
import { CatalogueComponent } from './catalogue/catalogue.component';
import { LogoutComponent } from './logout/logout.component';
import { AboutMeDivComponent } from './about-me-div/about-me-div.component';
import { SliderPrincipalComponent } from './slider-principal/slider-principal.component';
import { SectionsComponent } from './sections/sections.component';
import { PwaComponent } from './pwa/pwa.component';
import { PrimaryButtonComponent } from './primary-button/primary-button.component';
import { CatalogueHomeComponent } from './catalogue-home/catalogue-home.component';
import { SplashComponent } from './splash/splash.component';
import { CategoriesComponent } from './categories/categories.component';
import { CardCategorieComponent } from './card-categorie/card-categorie.component';
import { InfoSectionsComponent } from './info-sections/info-sections.component';
import { CatalogueProductsComponent } from './catalogue-products/catalogue-products.component';
import { TermsComponent } from './terms/terms.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { GalleriesOpenComponent } from './galleries-open/galleries-open.component';
import { VendedorRespuestaComentariosComponent } from '../pages/product-detail/components/vendedor-respuesta-comentarios/vendedor-respuesta-comentarios.component';
import { GalleriesComponent } from './galleries/galleries.component';
import { FilterComponent } from './filter/filter.component';
import { ShowTermsComponent } from './show-terms/show-terms.component';
import { SliderGaleriesComponent } from './slider-galeries/slider-galeries.component';
import { SearchbarComponent } from './searchbar/searchbar.component';
import { EmbedVideoComponent } from './embed-video/embed-video.component';
import { ProductCartComponent } from './product-cart/product-cart.component';
import { BanksComponent } from './banks/banks.component';
import { ChangeAvatarComponent } from './change-avatar/change-avatar.component';
import { BalanceToolbarComponent } from '../componentsWallet/components/balance-toolbar/balance-toolbar.component';
import { ContainerWalletComponent } from '../componentsWallet/components/container-wallet/container-wallet.component';
import { ClientsComponent } from '../componentsWallet/components/clients/clients.component';
import { HistoryComponent } from '../componentsWallet/components/history/history.component';
import { FavoriteListComponent } from './favorite-list/favorite-list.component';
import { SuccessfullyActionComponent } from '../componentsWallet/components/successfully-action/successfully-action.component';
import { RechargeComponent } from '../componentsWallet/components/recharge/recharge.component';
import { MultioComponent } from './multio/multio.component';
import { CardsLoadingComponent } from './cards-loading/cards-loading.component';
import { DebitComponent } from './debit/debit.component';
import { PaymentsComponentClient } from '../componentsWallet/components/payments/payments.component';
import { BalanceToolbarPaymentsComponent } from '../componentsWallet/components/balance-toolbar-payments/balance-toolbar-payments.component';
import { BranchComponent } from './branch/branch.component';
import { NameBranchComponent } from './name-branch/name-branch.component';
import { MessageCardComponent } from './message-card/message-card.component';
import { GiftSectionComponent } from '../componentsWallet/components/gift-section/gift-section.component';
import { GiftCardComponent } from '../componentsWallet/components/gift-card/gift-card.component';
import { CardButtonsComponent } from './card-buttons/card-buttons.component';
import { ActionClientComponent } from '../componentsWallet/components/action-client/action-client.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { StripeComponent } from './stripe/stripe.component';
import { FeaturedGalleryComponent } from './featured-gallery/featured-gallery.component';
import { SepecialButtonsComponent } from './sepecial-buttons/sepecial-buttons.component';
import { DetailedGalleryComponent } from './detailed-gallery/detailed-gallery.component';
import { SkeletonDetailComponent } from './skeleton-detail/skeleton-detail.component';
import { OptionsComponent } from '../pages/product-detail/components/options/options.component';
import { ConsultBtnComponent } from '../pages/product-detail/components/consult-btn/consult-btn.component';
import { LoadExcelComponent } from './load-excel/load-excel.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CategoriesProductDetailComponent } from './categories-product-detail/categories-product-detail.component';
import { MapsComponent } from './maps/maps.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { GiftCardSelectionComponent } from './gift-card-selection/gift-card-selection.component';
import { GiftCardHomeComponent } from './gift-card-home/gift-card-home.component';
import { PaypalComponent } from './paypal/paypal.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { HeaderStylesDirective } from '../directives/header-styles.directive';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ZoomImgComponent } from './zoom-img/zoom-img.component';
import { InstagramComponent } from './instagram/instagram.component';
import { InstagramSrcDirective } from '../directives/instagram-src.directive';
import { CardProductosTwoComponent } from './card-productos-two/card-productos-two.component';
import { ContainerCardProductComponent } from './container-card-product/container-card-product.component';
import { InitialKitComponent } from './initial-kit/initial-kit.component';
import { MarketplaceBaseComponent } from './marketplace-base/marketplace-base.component';
import { InteractiveCatalogueComponent } from './interactive-catalogue/interactive-catalogue.component';
import { AddAnimDirective } from '../directives/add-anim.directive';
import { DirectivesModule } from '../directives/directives.module';
import { HomeVideoComponent } from './home-video/home-video.component';
import { ResponsivePictureSliderComponent } from './responsive-picture-slider/responsive-picture-slider.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

const components = [
  MenuComponent,
  HeadercomponentComponent,
  CardProductosComponent,
  SlidesCardHomeComponent,
  FooterComponent,
  PagerComponent,
  PopoverTagsComponent,
  ShowProductClientComponent,
  CatalogueComponent,
  LogoutComponent,
  AboutMeDivComponent,
  SliderPrincipalComponent,
  SectionsComponent,
  PwaComponent,
  PrimaryButtonComponent,
  CatalogueHomeComponent,
  SplashComponent,
  CategoriesComponent,
  CardCategorieComponent,
  InfoSectionsComponent,
  CatalogueProductsComponent,
  TermsComponent,
  SponsorsComponent,
  GalleriesOpenComponent,
  VendedorRespuestaComentariosComponent,
  GalleriesComponent,
  FilterComponent,
  ShowTermsComponent,
  SliderGaleriesComponent,
  SearchbarComponent,
  EmbedVideoComponent,
  ProductCartComponent,
  BanksComponent,
  ChangeAvatarComponent,
  BalanceToolbarComponent,
  ContainerWalletComponent,
  ClientsComponent,
  HistoryComponent,
  FavoriteListComponent,
  SuccessfullyActionComponent,
  RechargeComponent,
  MultioComponent,
  CardsLoadingComponent,
  DebitComponent,
  PaymentsComponentClient,
  BalanceToolbarPaymentsComponent,
  BranchComponent,
  NameBranchComponent,
  MessageCardComponent,
  GiftSectionComponent,
  GiftCardComponent,
  CardButtonsComponent,
  ActionClientComponent,
  BreadcrumbsComponent,
  StripeComponent,
  FeaturedGalleryComponent,
  SepecialButtonsComponent,
  DetailedGalleryComponent,
  SkeletonDetailComponent,
  OptionsComponent,
  ConsultBtnComponent,
  LoadExcelComponent,
  CalendarComponent,
  CategoriesProductDetailComponent,
  MapsComponent,
  NotificationsComponent,
  InfiniteScrollComponent,
  GiftCardSelectionComponent,
  GiftCardHomeComponent,
  PaypalComponent,
  HeaderStylesDirective,
  ZoomImgComponent,
  InstagramComponent,
  InstagramSrcDirective,
  CardProductosTwoComponent,
  ContainerCardProductComponent,
  InitialKitComponent,
  MarketplaceBaseComponent,
  InteractiveCatalogueComponent,
  AddAnimDirective,
  HomeVideoComponent,
  ResponsivePictureSliderComponent,
];

@NgModule({
  declarations: components,
  imports: [
    CommonModule,
    PipeModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    TranslateModule,
    RouterModule,
    DirectivesModule,
  ],
  exports: components,
})
export class ComponentsModuleModule {}
