import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-me-div',
  templateUrl: './about-me-div.component.html',
  styleUrls: ['./about-me-div.component.scss'],
})
export class AboutMeDivComponent implements OnInit {
  wpp = environment.whatsapp;
  constructor() { }

  ngOnInit() {}

}
