import { AfterContentInit, Directive, ElementRef, EventEmitter, HostListener, Input, Output, Renderer2 } from '@angular/core';
import { InteractiveService } from '../services/interactive.service';

@Directive({
  selector: '[appSwipeCatalogue]'
})
export class SwipeCatalogueDirective implements AfterContentInit {
  @Output() changePage = new EventEmitter();
  @Input() set catalogue(value){
    if(value && this.elPrev){
      this.lastMoveX = this.lastMoveX * -1;
      this.elPrev.firstChild.style.transform = `translateX(${this.lastMoveX}%)`;
      setTimeout(() => {
        this.elPrev.firstChild.style.opacity = `1`;
        this.elPrev.firstChild.style.transform = `translateX(0%)`;
        this.renderer.removeClass(this.elPrev, 'moving');
      }, 100)
    }
  }
  private _isPressing = false;
  private _widthCol = 0; // 
  public init = 0;
  public end = 0;
  public lastMove = 0;
  public elPrev = undefined;
  public lastMoveX = 0;

  get widthCol(){
    return this._widthCol
  }

  set widthCol(value){
    this._widthCol = value;
  }

  get isPressing(){
    return this._isPressing
  }

  set isPressing(value){
    this._isPressing = value;
  }

  // MOVIL EVENTOS

  @HostListener('touchstart', ['$event']) touchStart(ev: TouchEvent){
    this.moveStart(ev.changedTouches[0].screenX);
  }
  
  @HostListener('touchmove', ['$event']) doSwipe(ev: TouchEvent){
    if(this.isPressing){
      this.move(ev.changedTouches[0].screenX, ev.target);
    }
  }

  @HostListener('touchend', ['$event']) touchEnd(ev: TouchEvent){
    this.moveEnd(ev.changedTouches[0].screenX, ev.target);
  }


  //EVENTOS DE MOUSE


  @HostListener('mousedown', ['$event']) mouseDown(ev: MouseEvent){
    this.moveStart(ev.offsetX);
  }


  @HostListener('mousemove', ['$event']) doSwipeMouse(ev: MouseEvent){
    if(this.isPressing){
      this.move(ev.offsetX, ev.target);
    }
  }
 
  @HostListener('mouseup', ['$event']) mouseUp(ev: MouseEvent){
    this.moveEnd(ev.offsetX, ev.target);
  }

  @HostListener('mouseleave', ['$event']) mouseLeave(ev: any  ){
    if(this.isPressing) {
      this.moveEnd(ev.offsetX, ev.target.firstChild.firstChild.firstChild);
    };
  }

  moveStart(offsetX){
    // Debo calcular el momento desde que se presiona hasta que se suelta
    // dependiendo la cantida de pixeles que haya arrastrado significa que debo cambiar la pagina
    this.init = offsetX;
    this.isPressing = true;
  }

  move(offsetX, target){
    if(target.firstChild?.tagName === 'ION-IMG'){
      const diffInPx = this.init - offsetX;
      const movedPx = Math.abs( diffInPx );
      const movedPxToPercent = (movedPx * 100) / this.widthCol;
      const movement = Math.round( Math.sign(diffInPx) === -1 ? movedPxToPercent : movedPxToPercent * -1 );
      if(this.lastMove !== movement){
        this.renderer.setStyle(target.firstChild, 'transform', `translateX(${ movement }%)`)
        this.renderer.setStyle(target.firstChild, 'opacity', `${1 - (Math.abs( movement ) / 100)}`)
        if(!target.classList.contains('moving')){
          this.renderer.addClass(target, 'moving');
        }
        this.lastMove = movement;
      }
    }
  }

  moveEnd(offsetX, target){
    // if(target.firstChild){
      this.isPressing = false;
      this.end = offsetX
      const quartOfContainer = this.widthCol * 0.25;
      const slided = this.init - this.end;
      if(Math.abs(slided) >= quartOfContainer){
        this.lastMoveX = 0;
        let opacity = 1;
        if(Math.sign(slided) === -1 && this.interactiveSv.page !== 1){
          this.changePage.emit(false);
          this.lastMoveX = 100;
          opacity = 0;
        }else if(Math.sign(slided) === 1 && this.interactiveSv.page >= 1 && this.interactiveSv.page !== this.interactiveSv.totalPages ){
          this.changePage.emit(true);
          this.lastMoveX = -100
          opacity = 0;
        }
        target.firstChild.style.opacity = opacity;
        target.firstChild.style.transform = `translateX(${this.lastMoveX}%)`;
        this.elPrev = target;
      }else{
        target.firstChild.style.transform = 'translateX(0%)';
        target.firstChild.style.opacity = 1;
        this.renderer.removeClass(target, 'moving');
      }
    // }
    // if(target.firstChild.tagName === 'ION-IMG'){
  }

  constructor(
    private el: ElementRef<any>,
    private renderer: Renderer2,
    private interactiveSv: InteractiveService
  ) { 
  }

  ngAfterContentInit(): void {
    setTimeout(() => {
      this.widthCol = this.el.nativeElement.offsetWidth; // la cantidad de px del contenedor es igual a 100%
    }, 500)
  }

}