import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-responsive-picture-slider',
  templateUrl: './responsive-picture-slider.component.html',
  styleUrls: ['./responsive-picture-slider.component.scss'],
})
export class ResponsivePictureSliderComponent implements OnInit {
  @Input() slideData: any = undefined;
  @Input() typeImg: string = '';

  constructor() {}

  ngOnInit() {}
}
