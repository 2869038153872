import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'query'
})
export class QueryPipe implements PipeTransform {

  transform(product): any {
    if(
      product?.inscriptionPrice 
      || product?.inscriptionPrice === 0 
      || product?.inscriptionPrice === null
    ){
      const queryParams = { sbp: 1 };
      return queryParams
    }else if(product.type === 'redeemable'){
      const queryParams = { type: 'reedem' };
      return queryParams
    }
  }

}
