import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-paypal',
  templateUrl: './paypal.component.html',
  styleUrls: ['./paypal.component.scss'],
})
export class PaypalComponent implements OnInit {
  @Input() total: any = 0;
  @Output() sendData = new EventEmitter();

  public payPalConfig?: IPayPalConfig;
  private _key = undefined;

  constructor(private paramSv: ParamServicesService) {}

  ngOnInit() {
    this._key = this.paramSv?.paypal?.key || '';
    this.initConfig();
  }

  private initConfig(): void {
    this.payPalConfig = {
      currency: 'USD',
      clientId: this._key,
      createOrderOnClient: (data) =>
        <ICreateOrderRequest>{
          intent: 'CAPTURE',
          purchase_units: [
            {
              amount: {
                currency_code: 'USD',
                value: this.total,
                breakdown: {
                  item_total: {
                    currency_code: 'USD',
                    value: this.total,
                  },
                },
              },
            },
          ],
        },
      advanced: {
        commit: 'true',
      },
      style: {
        color: 'gold',
        shape: 'rect',
        label: 'paypal',
        layout: 'vertical',
      },
      onApprove: (data, actions) => {
        actions.order.capture().then((data) => {
          const formData = new FormData();
          const transactionId = data.purchase_units[0].payments.captures[0].id;
          formData.set('reference', transactionId);
          this.sendData.emit(formData);
        });
      },
      onClientAuthorization: (data) => {
        // console.log('onClientAuthorization - you should probably inform your server about completed transaction at this point', data);
        // this.showSuccess = true;
      },
      onError: (err) => {
        console.error('OnError', err);
        // this.showError = true;
      },
    };
  }
}
