import { Injectable, OnDestroy, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { filter, map } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ConfigMeta } from '../interfaces/interfaces';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})

export class MetadataService implements OnDestroy{
  // private domainName = this.paramSv.domainName;
  private routeObs = new Subscription();
  private ogUrl = '';
  private domainName: string = environment.domainName;

  constructor(
    private metaTagService: Meta,
    private title: Title,
    private route: ActivatedRoute,
    private router: Router,
    private loginSv: LoginService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.loginSv.getTokenUser();
  }
  // private async imageReadFileInfo(urlImage) {
  //     return new Promise((resolve, reject) => {
  //       let img = new Image()
  //       img.onerror = reject
  //       img.src = urlImage
  //       img.onload
  //       resolve(img)
  //     })
  // }

  public async updateMetadata(config: ConfigMeta, img?) {
    // if(!this.domainName){
      // this.domainName = await this.paramSv.getOnlyParam('domainName');
    // }
    const description = config.description || this.domainName;
    const image = config.image || img;
    let price = ''
    if (config?.promotionalPrice || config?.price) {
      const currency = config.meta?.currency
      price = `${ config?.promotionalPrice || config?.price } ${ currency && JSON.parse(currency).symbol }`
    }
    const title = config.name
      ? `${config.name} ${ price || '' } - ${this.domainName}`
      : this.domainName;
    // const titleNode = this.doc.getElementById('titlePageIndex')
    const titleNode = this.doc.querySelector('meta[charset=utf-8]')
    let imgThum = image
    if (config.media[0]?.width) {
      // const indexFileName = config.media[0]?.file.lastIndexOf('.')
      let fileName = config.media[0]?.file
      imgThum = `${config.serverUrl}/file/product/thumb/${fileName}`
    }
    const existLink: any = this.doc.querySelector('link[rel=preload]')
    if (!existLink) {
      let link: HTMLLinkElement = this.doc.createElement('link');
      link.setAttribute('as', 'image');
      link.setAttribute('rel', 'preload');
      link.setAttribute('fetchpriority', 'high');
      link.setAttribute('href', imgThum);
      this.doc.head.insertBefore(link, titleNode)
    } else if (existLink.href !== imgThum) {
      existLink.setAttribute('href', imgThum);
    }

      // ? `${config.name} - ${this.domainName}`
      // : this.domainName;
    this.metaTagService.updateTag({ property: 'og:image', itemprop: 'image', content: imgThum });
    this.metaTagService.updateTag({ property: 'og:image:secure_url', content: imgThum });
    this.metaTagService.updateTag({ property: 'og:image:width', content: config.media[0]?.widthThumb || 250 });
    this.metaTagService.updateTag({ property: 'og:image:height', content: config.media[0]?.heightThumb || 250 });
    this.metaTagService.updateTag({ property: 'og:title', content: title });
    this.metaTagService.updateTag({ property: 'og:description', content: description });
    const tags = [
      { property: 'og:image:alt', content: `${ config.media[0]?.alt || 'imagen del producto' }` },
      { name: 'description', content: description },
      { property: 'og:url', content:  this.ogUrl },
      { property: 'og:site_name', content: this.domainName },
      // { property: 'og:title', content:  title },
      { property: 'fb:app_id', content:  "908621720271347" },
      // { property: 'og:description', content: description },
      { property: 'og:type', content: 'website' },
      { property: 'og:locale', content: 'es_ES' },
      { property: 'og:locale:alternate', content: 'en_US' },
      { property: 'og:updated_time', content: config.updated_at },


      // { property: 'twitter:domain', content: 'happylandonline.com'},
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:image', content: image },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
     
    ];
    this.title.setTitle(title);
    this.metaTagService.addTags(tags);

    let linkImage: HTMLLinkElement = this.doc.createElement('link');
    linkImage.setAttribute('rel', 'image_src');
    linkImage.setAttribute('href', image);
    this.doc.head.appendChild(linkImage);
    let linkImageBody: HTMLLinkElement = this.doc.createElement('link');
    linkImageBody.setAttribute('itemprop', 'thumbnailUrl');
    linkImageBody.setAttribute('href', imgThum);
    this.doc.body.appendChild(linkImageBody);

    let spanLink: HTMLSpanElement = this.doc.createElement('span');
    spanLink.setAttribute('itemprop', 'thumbnail');
    spanLink.setAttribute('itemscope', "");
    spanLink.setAttribute('itemtype', "http://schema.org/ImageObject");
    let childLinkSpan: HTMLLinkElement = this.doc.createElement('link')
    childLinkSpan.setAttribute('itemprop', 'url')
    childLinkSpan.setAttribute('href', imgThum)
    let metaChildW: HTMLMetaElement = this.doc.createElement('meta')
    metaChildW.setAttribute('itemprop', 'width')
    metaChildW.setAttribute('content', config.media[0]?.widthThumb)
    let metaChildH: HTMLMetaElement = this.doc.createElement('meta')
    metaChildH.setAttribute('itemprop', 'height')
    metaChildH.setAttribute('content', config.media[0]?.heightThumb)
    spanLink.appendChild(childLinkSpan)
    spanLink.appendChild(metaChildW)
    spanLink.appendChild(metaChildH)
    this.doc.body.appendChild(spanLink);
  }

  subscribeData(){
    // const obj = {
    //   name: 'Este es un producto',
    //   image: 'https://load.neuronweb.online/file/product/1671120323936.png',
    //   description: 'Esta es una descripcion'
    // }
    // this.updateMetadata(obj);

    const routerObs = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.route),
      map(route => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter(route => route.outlet === 'primary')
    ).subscribe(() => {
      const routeObs = this.route.data.subscribe(() => {
        this.ogUrl = window.location.href;
        if(!window.location.pathname.includes('product-detail')){
          if(this.domainName){
            this.title.setTitle(this.domainName);
          }
        }
      })
      this.routeObs.add(routeObs);
    })

    this.routeObs.add(routerObs);
  }

  public async metaHome(data) {
    if(data?.logo){
      this.metaTagService.updateTag({ property: 'og:image', content: data.logo });
    }
    
    if (data?.description) {
      this.metaTagService.updateTag({ property: 'og:description', content: data?.description });
      this.metaTagService.updateTag({ property: 'description', content: data?.description });
    }

    if (data?.keywords) {
      this.metaTagService.updateTag({ property: 'og:keywords', content: data.keywords });
      this.metaTagService.updateTag({ property: 'keywords', content: data.keywords });
    }

    if (data?.googleSiteVerification) {
      this.metaTagService.updateTag({ property: 'og:google-site-verification', content: data.googleSiteVerification });
    }

  }

  ngOnDestroy(): void {
    this.routeObs.unsubscribe();
  }

}