import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-banks',
  templateUrl: './banks.component.html',
  styleUrls: ['./banks.component.scss'],
})
export class BanksComponent implements OnInit {
  @Input() bank: any;
  constructor() { }

  ngOnInit() {
  }

}
