import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { CartServiceService } from 'src/app/services/cart-service.service';
import { HttpService } from 'src/app/services/http.service';
import { LoginService } from 'src/app/services/login.service';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-marketplace-base',
  templateUrl: './marketplace-base.component.html',
  styleUrls: ['./marketplace-base.component.scss'],
})
export class MarketplaceBaseComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output() scrollContent = new EventEmitter();
  public dataFilter = {};
  public products: any[] = [];
  public ads: any[];
  public filters: any = {}
  public searching = true;
  public arr = new Array(4);
  public page = 1;
  public totalPages = undefined;
  public buyControl: any = {};
  private _subscribable: Subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private httpSv: HttpService,
    private navCtrl: NavController,
    private cartSv: CartServiceService,
    private paramSv: ParamServicesService,
    private loginSv: LoginService
  ) { }

  ngOnInit() {}

  trackByItems(index, item){
    return item.id;
  }
  
  async ngAfterViewInit(){
    this._subscribable.add(this.route.queryParams.subscribe(async param => {
      this.filters = { ...param };
      if(this.page !== this.filters.pag){
        this.page = this.filters.pag;
      }
      await this.doSearch();
    }))
    const res = await this.comprobeProduct();
    if(!res){
      this._subscribable.add(
        this.paramSv.emitImagesObs.subscribe(async (item: any) => {
          if(item.name === 'buyControl'){
            this.buyControl = this.paramSv.buyControl;
            await this.fixProducts();
          }
      }))
    }
  }

  async fixProducts(){
    this.products = this.paramSv.fixProducts(this.products, this.loginSv.user);
  }

  async doSearch(){
    try{
      this.httpSv.loadingIndicatorSrc.next(this.searching);
      const res: any = await this.httpSv.searchProduct(this.filters);
      const products: any[] = res.data.product.data;
      this.products = products;
      await this.comprobeProduct();
      this.dataFilter = {
        categories: res.data.categories,
        sponsors: res.data.sponsors
      };
      this.totalPages = res.data.product.meta.last_page;
      this.ads = res.data.ads;
      this.searching = false;
      this.httpSv.loadingIndicatorSrc.next(this.searching);
    }catch(err){
      console.error(err);
    }
  }

  async comprobeProduct(){
    if(this.paramSv.buyControl){
      this.buyControl = this.paramSv.buyControl;
      await this.fixProducts();
      return true;
    }
    return false
  }

  async setFilter(ev){
    for(const key of Object.keys(ev)){
      const value = ev[key];
      this.filters[key] = value;
    }
    this.cartSv.marketplaceFilers = {...this.cartSv.marketplaceFilers, ...this.filters};
    await this.navCtrl.navigateForward('marketplace', { queryParams: {...this.filters} });
  }

  ngOnDestroy(): void {
    this._subscribable.unsubscribe();
    this.cartSv.clearFilter();
  }

  async changePage(ev){
    this.page = ev;
    this.cartSv.marketplaceFilers.pag = this.page;
    // this.content.scrollToTop(400);
    this.scrollContent.emit();
    this.filters.pag = this.page;
    this.searching = true;
    this.products = [];
    setTimeout(async () => {
      await this.navCtrl.navigateForward( 'marketplace', { queryParams: {...this.filters} } );
    }, 500)
  }

}
