import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { UiServices } from 'src/app/services/ui-services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  public logo: string = '';
  public appStore: any = undefined;
  public playStore: any = undefined;
  public copyRightTxt: any = {};
  public devTxt: any = {};
  public socials = [];
  public playstoreImg: any = './assets/icons/playstore.svg';
  public appstoreImg: any = './assets/icons/applestore.svg';
  public paramSuscription = new Subscription();
  public existDistributorSignUp: any;

  constructor(
    public paramSv: ParamServicesService,
    private navCtrl: NavController,
    private uiSv: UiServices,
    private translateSv: TranslateService
  ) { }

  ngOnInit() {
    const footerParam = this.paramSv.footerParams;
    const savedLogo = this.paramSv.savedLogo;
    this.existDistributorSignUp = this.paramSv.existDistributorSignUp;
    (footerParam?.value) && this.setProperties(footerParam);
    (savedLogo?.length) && this.setLogo();
    this.paramSuscription = this.paramSv.emitImagesObs.subscribe((item: any) => {
      if(item?.name === 'footerOpts'){
        this.setProperties(item?.value);
      }else if(item?.name === 'logo'){
        this.setLogo();
      }else if(item?.name === 'distributor'){
        this.existDistributorSignUp = this.paramSv.existDistributorSignUp;
      }else if(item === 'finish'){
        this.paramSuscription.unsubscribe();
      }
    });
  }

  async go(url){
    await this.navCtrl.navigateForward(url);
  }

  async open(option){
    if(option.external){
      window.open(option.route, '_blank')
    }else{
      await this.go(option.route);
    }
  }

  ngOnDestroy(): void {
    this.paramSuscription.unsubscribe();
  }

  setLogo(){
    const logoFt = this.paramSv.setLogo('Footer');
    this.logo = logoFt;
  }

  setProperties(item){
    const footerParams = item;
    this.socials = footerParams?.value?.selectedSocials;
    this.copyRightTxt = footerParams?.value?.footerOpts?.find(item => item.text === 'Texto Copyright');
    this.devTxt = footerParams?.value?.footerOpts?.find(item => item.text === 'Text Developer');
    const playStore = footerParams?.value?.footerOpts?.find(item => item.text === 'Enlace PlayStore')?.value;
    const appStore = footerParams?.value?.footerOpts?.find(item => item.text === 'Enlace AppStore')?.value;
    this.playStore = { link: playStore };
    this.appStore = { link: appStore };
  }

  async goTo(social){
    if(social.link === 'email'){
      await navigator.clipboard.writeText(social.value);
      const translate = this.translateSv.instant('MAIL_COPIADO_AL_PORTAPAPALES');
      return await this.uiSv.showToast(translate);
    }
    window.open(social.link, '_blank')
  }
  
  registerDist(){
    this.navCtrl.navigateForward('/auth', {
      queryParams: {to: 'register'}
    });
  }
}
