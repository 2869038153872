import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/services/http.service';
import { LoginService } from 'src/app/services/login.service';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { UiServices } from 'src/app/services/ui-services';

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.scss'],
})
export class RechargeComponent implements OnInit {
  @Input() type: string = '';
  @Input() id: number;
  @Input() balance: number;
  public logo: string;
  public ammount: number;

  constructor(
    private paramSv: ParamServicesService,
    public modalController: ModalController,
    private uiSv: UiServices,
    private httpSv: HttpService,
    private loginSv: LoginService,
    private trSv: TranslateService
  ) { }

  ngOnInit() {
    const modal = this.paramSv.setLogo('Modal');
    this.logo = modal;
  }

  async doRecharge(){
    try{
      if(this.type === 'debit' && this.balance && this.ammount > this.balance){
        const tr = this.trSv.instant('EL_MONTO_EXCEDE_EL_BALANCE_DEL_USUARIO_SI_PROCEDE_EL_USUARIO_QUEDARA_CON_UN_SALDO_NEGATIVO');
        const { role } = await this.uiSv.presentAlert(tr);
        if(role === 'cancel'){
          return true;
        }
      }
      if(this.type !== 'payCredit'){
        const data = new FormData();
        data.set('amount', String(this.ammount));
        this.id && data.set('isAdmin', '1');
        await this.uiSv.showLoading();
        const res: any = await this.httpSv.doTransaction(data, this.type, this.loginSv.user.id, this.id ? this.id : this.loginSv.user.id)
        await this.uiSv.loading.dismiss();
        res.data.data.ammount = this.ammount;
        this.modalController.dismiss(res.data.data);
      }else{
        await this.modalController.dismiss({ ammount: this.ammount, operation: 'Pagar credito' , reason: 'payCredit' });
      }
    }catch(err){
      console.error(err);
    }
  }

}
