import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IonSelect } from '@ionic/angular';

@Component({
  selector: 'app-consult-btn',
  templateUrl: './consult-btn.component.html',
  styleUrls: ['./consult-btn.component.scss'],
})
export class ConsultBtnComponent implements OnInit {
  @Input() data: any = undefined;
  @Input() buyControl: any = undefined;
  @Output() emitWpp = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  async openSelect(select: IonSelect){
    if(this.data.branchContactId.length > 1){
      await select.open();
    }else{
      const num = this.data.branchContactId[0]?.number || undefined;
      this.openWpp({detail: {value: num}});
    }
  }

  openWpp(num){

    this.emitWpp.emit(num);
  }

}
