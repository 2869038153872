import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';
import { StorageService } from 'src/app/services/storage.service';

@Injectable({
  providedIn: 'root'
})
export class GuardAdminGuard implements CanActivate {

  constructor(
    private storageSv: StorageService,
    private navCtrl: NavController
  ){}

  async canActivate(): Promise<boolean>{
    return new Promise(resolve => {
      setTimeout(async () => {
        const canAcces = await this.storageSv.get('userInfo');
        if(canAcces?.user?.rolId === 3){
          resolve(true);
        }else{
          resolve(this.navCtrl.navigateBack('/'));
        }
      }, 200)
    })
  }
  
}
