import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { NavController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class CarrouselGuard implements CanActivate {
  constructor(
    private navCtrl: NavController
  ){}

  canActivate() {
    const item = localStorage.getItem('carrousel');
    if(item === '1'){
      return this.navCtrl.navigateForward('home');
    }
    return true;
  }
  
}
