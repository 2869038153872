import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcSwipes'
})
export class CalcSwipesPipe implements PipeTransform {

  transform(col: number){
    const availableCol = 12 - (col || 0);
    const slidesPerView = Math.round( (availableCol / 12) * 4 );
    const slidesOpts: any = {
      breakpoints: {
        320: {
          slidesPerView: 2.1,
          spaceBetween: 0,
        },
        420: {
          slidesPerView: 3.1,
          // spaceBetween: 20,
        },
        768: {
          slidesPerView,
          spaceBetween: 0,
        },
        // 1270: {
        //   slidesPerView: 3,
        //   spaceBetween: 0,
        // },
      }
    }
    return slidesOpts;
  }

}
