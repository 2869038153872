import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ParesPipe } from './pares.pipe';
import { FixPagePipe } from './fixPage.pipe';
import { DomSanitizerPipe } from './dom-sanitizer.pipe';
import { FixUrlPipe } from './fix-url.pipe';
import { FixStylePipe } from './fix-style.pipe';
import { FilterImagePipe } from './filter-image.pipe';
import { TrustVideoPipe } from './trust-video.pipe';
import { CurrencyPipe } from './currency.pipe';
import { RouterPipe } from './router.pipe';
import { QueryPipe } from './query.pipe';
import { CalcPercentComissionPipe } from './calc-percent-comission.pipe';
import { CalcProgressRewardPipe } from './calc-progress-reward.pipe';
import { CalcSwipesPipe } from './calc-swipes.pipe';
import { CalcPointsPipe } from './calc-points.pipe';
import { CalcTotalWithComissionPipe } from './calc-total-with-comission.pipe';
import { IncentiveRangeLevelPipe } from './incentive-range-level.pipe';
import { FindSeasonPipe } from './find-season.pipe';
import { CalcTotalToPayPipe } from './calc-total-to-pay.pipe';
import { CalcPercentPerLevelPipe } from './calc-percent-per-level.pipe';
import { FilterResumeCartPipe } from './filter-resume-cart.pipe';
import { TranscurredSeasonPipe } from './transcurred-season.pipe';
import { TaxPipe } from './tax.pipe';
import { PlusSeasonsPointsPipe } from './plus-seasons-points.pipe';

const pipes = [
  ParesPipe,
  FixPagePipe,
  DomSanitizerPipe,
  FixUrlPipe,
  FixStylePipe,
  FilterImagePipe,
  TrustVideoPipe,
  CurrencyPipe,
  RouterPipe,
  QueryPipe,
  CalcPercentComissionPipe,
  CalcProgressRewardPipe,
  CalcSwipesPipe,
  CalcPointsPipe,
  CalcTotalWithComissionPipe,
  IncentiveRangeLevelPipe,
  FindSeasonPipe,
  CalcTotalToPayPipe,
  CalcPercentPerLevelPipe,
  FilterResumeCartPipe,
  TranscurredSeasonPipe,
  TaxPipe,
  PlusSeasonsPointsPipe,
];

@NgModule({
  declarations: pipes,
  imports: [CommonModule],
  exports: pipes,
})
export class PipeModule {}
