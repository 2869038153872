import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'incentiveRangeLevel'
})
export class IncentiveRangeLevelPipe implements PipeTransform {

  transform(value: any, amount: number): boolean {
    let disabled = true;
    const init = Number(value.init)
    const end = Number(value.end);
    amount = amount || 0;
    if(amount >= init && amount < end){
      disabled = false;
    }
    return disabled;
  }

}
