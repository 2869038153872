import { Injectable, EventEmitter } from '@angular/core';
import { UiServices } from './ui-services';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  public sendData = new EventEmitter();
  public stripeErrors = {
    approve_with_id: 'El pago no ha sido autorizado.',
    call_issuer: 'La tarjeta ha sido rechazada por motivos desconocidos.',
    card_not_supported: 'La tarjeta no es compatible con esta clase de compra.',
    card_velocity_exceeded:	'El cliente ha excedido el balance o crédito disponible en su tarjeta.',
    currency_not_supported:	'La tarjeta no es compatible con la divisa indicada.',
    do_not_honor:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    do_not_try_again:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    duplicate_transaction: 'Se ha realizado una transacción idéntica con la misma información de tarjeta de crédito hace poco.',
    expired_card:	'La tarjeta ha expirado.',
    fraudulent:	'El pago ha sido rechazado por Stripe al sospechar que es fraudulento.',
    generic_decline:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    incorrect_number:	'El número de la tarjeta no es correcto.',
    incorrect_cvc: 'El número CVC no es correcto.',
    incorrect_pin:	'El PIN ingresado no es correcto. Este código de error sólo aplica a pagos realizados con un lector de tarjeta.',
    incorrect_zip:	'El ZIP o código postal no es correcto.',
    insufficient_funds:	'La tarjeta no tiene los fondos disponibles para realizar la compra.',
    invalid_account:	'La tarjeta (o la cuenta a la que está conectada la tarjeta) no es válida.',
    invalid_amount:	'El monto del pago no es válido o excede el monto permitido.',
    invalid_cvc:	'El número CVC no es correcto.',
    invalid_expiry_year:	'El año de expiración de la tarjeta no es válido.',
    invalid_number:	'El número de la tarjeta es incorrecto.',
    invalid_pin:	'El PIN ingresado no es correcto. Este código de error sólo aplica a pagos realizados con un lector de tarjeta.',
    issuer_not_available:	'No se puede contactar a la entidad emisora de la tarjeta, por lo que pago es rechazado.',
    lost_card:	'El pago ha sido rechazado porque la tarjeta ha sido reportada perdida.',
    merchant_blacklist:	'El pago ha sido rechazado porque es igual a un valor en la lista de usuarios bloqueados de Stripe.',
    new_account_information_available:	'La tarjeta (o la cuenta a la que está conectada la tarjeta) no es válida.',
    no_action_taken:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    not_permitted:	'El pago no ha sido autorizado.',
    pickup_card:	'No se puede procesar el pago con esta tarjeta (puede deberse a que ha sido reportada perdida o robada).',
    pin_try_exceeded:	'Se ha excedido el número permitido de intentos de ingreso de PIN.',
    processing_error:	'Se ha presentado un error al procesar el pago con la tarjeta.',
    reenter_transaction:	'La entidad emisora de la tarjeta no ha procesado el pago por motivos desconocidos.',
    restricted_card:	'No se puede procesar el pago con esta tarjeta (puede deberse a que ha sido reportada perdida o robada).',
    revocation_of_all_authorizations:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    revocation_of_authorization:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    security_violation:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    service_not_allowed:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    stolen_card:	'El pago ha sido rechazado porque la tarjeta ha sido reportada robada.',
    stop_payment_order:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    testmode_decline:	'Se ha usado el número de una tarjeta de prueba de Stripe.',
    transaction_not_allowed:	'La tarjeta ha sido rechazada por motivos desconocidos.',
    try_again_later:	'La tarjeta ha sido rechazada por motivos desconocidos, intenta de nuevo mas tarde.',
    withdrawal_count_limit_exceeded:	'Se ha excedido el balance o límite de crédito disponible en la tarjeta.',
    amount_too_small: 'La cantidad especificada es menor que la cantidad mínima permitida. Use una cantidad mayor a $1 USD y vuelva a intentarlo.',
    balance_insufficient: 'No se pudo completar la transferencia o el pago porque la cuenta asociada no tiene suficiente saldo disponible. Cree una nueva transferencia o pago utilizando un monto menor o igual al saldo disponible de la cuenta.',
    card_declined: 'La tarjeta ha sido rechazada'
  }

  constructor(
    private uiSv: UiServices,
  ) {
  }

  reportError(err){
    const error = JSON.parse(err.error);
    const msg = this.stripeErrors[error.code];
    this.uiSv.presentAlert(msg);
  }

}
