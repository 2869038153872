import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'plusSeasonsPoints',
})
export class PlusSeasonsPointsPipe implements PipeTransform {
  transform(value: any[], level: number): number {
    return (
      value
        ?.filter((item) => {
          if (level === 1) return item.level === level;
          else return item.level !== 1;
        })
        .reduce((a, b) => {
          return (a += b.amount);
        }, 0) || 0
    );
  }
}
