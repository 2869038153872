import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcTotalToPay',
})
export class CalcTotalToPayPipe implements PipeTransform {
  transform(commission: number, product: any): number {
    const price =
      product.promotionalPrice || product.price || product.sale_price;
    return price - commission;
  }
}
