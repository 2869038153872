import { Component, OnInit, Input } from '@angular/core';
import { LoginService } from 'src/app/services/login.service';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-message-card',
  templateUrl: './message-card.component.html',
  styleUrls: ['./message-card.component.scss'],
})
export class MessageCardComponent implements OnInit {
  @Input() loginSv: LoginService;
  @Input() product: any;
  @Input() promotionalPrice: any;
  @Input() promotionalPriceWSale: any;

  public offer: any;
  public featured: any;
  public isNewer: any;

  constructor(
    public paramSv: ParamServicesService
  ) { }

  calcDays(days){
    days = Number(days);
    const res = new Date();
    res.setDate(res.getDate() - days);
    return res;
  }

  ngOnInit() {
    this.setOptions();
    const param = this.paramSv.emitImagesObs.subscribe((item: any) => {
      if(item.name === 'cardMessages'){
        this.setOptions();
      }else if(item === 'finish'){
        param.unsubscribe();
      }
    })
  }

  setOptions(){
    if(!this.paramSv?.cardMessages) return false;
    const isDistributor = (this.loginSv.user?.rolId === 7) || (this.paramSv.existDistributorSignUp && this.loginSv.user?.rolId === 3)
    const isClient = (this.loginSv.user?.rolId !== 7 || this.loginSv.user?.rolId !== 3) || (!this.paramSv.existDistributorSignUp && this.loginSv.user?.rolId === 3)
    if(( isClient && this.promotionalPrice ) 
      || ( isDistributor && (this.promotionalPriceWSale || (!this.product.priceWSale && !this.promotionalPriceWSale && this.product.promotionalPrice)) )
    ){
      this.offer = true
    }else if(this.product.isSalient && !this.offer){
      this.featured = true;
    }else{
      const cardMsg = this.paramSv?.cardMessages;
      const time = cardMsg?.newTime || 15;
      const createdProduct = new Date(this.product.created_at);
      const compareDate = this.calcDays(time);
      this.isNewer = compareDate < createdProduct;
    }
  }

}
