import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http.service';
import { StorageService } from 'src/app/services/storage.service';
import { UiServices } from 'src/app/services/ui-services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-vendedor-respuesta-comentarios',
  templateUrl: './vendedor-respuesta-comentarios.component.html',
  styleUrls: ['./vendedor-respuesta-comentarios.component.scss'],
})
export class VendedorRespuestaComentariosComponent implements OnInit, OnDestroy {
  @Input() productId = null;
  @Input() galleryId = null;
  @Input() subscriptionId = null;
  @Input() userId;
  myUser;
  avatarFile = `${environment.url}/file`
  comentario: string = '';
  comments: any[] = [];
  sending = false;
  commentSus: Subscription;
  answeringReply;
  answering;
  defaultAvatar = environment.defaultImg;
  avatar = `${this.avatarFile}/`;
  domainId = environment.domainId;

  constructor(
    private httpSv: HttpService,
    private storageSv: StorageService,
    private uiSv: UiServices,
    private navCtrl: NavController,
    private trSv: TranslateService
  ) { }

  async ngOnInit() {
    try{
      const type = this.productId ? 1 : this.galleryId ? 0 : this.subscriptionId ? 3 : 2;
      const id = this.productId || this.galleryId || this.subscriptionId;
      const userInfo = await this.storageSv.get('userInfo');
      this.myUser = userInfo?.user;
      const res: any = await this.httpSv.itemIndex('comment',
        `${this.domainId}/${type}/${id}`
      );
      this.comments = res.data;
    }catch(err){
      console.error(err);
    }
    
    
    // this.compartirService.showComments(0, this.productId).subscribe((res:any) => {
      
    //   if(res.status){
    //   }
    // })
  }

  trackByItems(index, item){
    return item.id
  }

  async buscar(ev: any, to, id?) {
    const code = ev.keyCode || ev.which;
    if (code === 13) {
      if(to === 'new'){
        this.comentar()
      }else{    
        if(!ev.target.value.trim()){
          // return this.toastService.show('Debe contener mas de 1 carácter')
        }else{
          this.reply(id, ev.target.value)
          ev.target.value = '';
        } 
      }
    }
  }

  async comentar(){
    try{
      if(!this.myUser){
        const tr = this.trSv.instant('DEBES_REGISTRARTE_ANTES_DE_COMENTAR');
        const { role } = await this.uiSv.presentAlert(tr);
        if(role === 'accept'){
          await this.navCtrl.navigateBack('auth');
        }
        return
      }
      this.sending = true;
      const obj:any = {
        productId: this.productId,
        galleryId: this.galleryId,
        subscriptionId: this.subscriptionId,
        comment: this.comentario,
        child: [],
        domainId: this.domainId
      };
  
      const res: any = await this.httpSv.itemAction(obj, 'comment', 'store');
      this.sending = false;
      const myComment = {
        ...obj,
        user: {
          file: this.myUser?.file,
          name: this.myUser?.name
        },
        created_at: new Date()
      }
      myComment.id = res.data.id;
      this.comments.unshift(myComment)
      this.comentario = '';
    }catch(err){
      console.error(err);
    }
  }

  ngOnDestroy(): void {
    // this.commentSus.unsubscribe()
  }

  selectReply(id){
    this.answering = id;
  }

  async reply(id, comment){
    this.answeringReply = true;
    const obj:any = {
      productId: this.productId,
      comment: comment,
      parentId: id
    }
    await this.httpSv.itemAction(obj, 'comment', 'store');
    const parentComment = this.comments.find(x => x.id === id);
    !parentComment.child && (parentComment.child = []);
    parentComment.child.push({
      comment: comment,
      user: {
        file: this.myUser.file,
        name: this.myUser.name
      },
    })
    comment = '';
    this.answeringReply = false;
    // this.commentSus = this.compartirService.postComment(obj).subscribe(res => {
    // });
  }

  handleMissingImage(event){
    (event.target as HTMLImageElement).src = this.defaultAvatar;
  }

}
