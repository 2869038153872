import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-action-client',
  templateUrl: './action-client.component.html',
  styleUrls: ['./action-client.component.scss'],
})
export class ActionClientComponent implements OnInit {

  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  setAction(ev){
    const action = ev.detail.value; 
    this.popoverController.dismiss(action);
  }
}
