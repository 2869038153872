import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { InfiniteScroll } from 'src/app/interfaces/interfaces';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-interactive-product-selector',
  templateUrl: './interactive-product-selector.component.html',
  styleUrls: ['./interactive-product-selector.component.scss'],
})
export class InteractiveProductSelectorComponent implements OnInit {
  @ViewChild('infiniteScroll') infiniteScroll: InfiniteScroll;
  private _filters = {
    search: '',
    pag: 1,
    domainId: environment.domainId
  }
  private _totalPages = 1;
  public products = [];

  constructor(
    private httpSv: HttpService,
    private modalController: ModalController
  ) { }

  ngOnInit() {}

  async getProducts(){
    try{
      const res: any = await this.httpSv.searchProduct(this._filters);
      const products: any[] = res.data.product.data;
      this.products.push(...products);
      this._totalPages = res.data.product.meta.last_page;
    }catch(err){
      console.error(err);
    }
  }

  async loadData(ev: InfiniteScroll){
    ev.disconnect();
    await this.getProducts();
    this._filters.pag++;
    ev.observe();
    if(this._filters.pag >= this._totalPages){
      ev.complete();
      return true;
    }
  }

  select(product){
    const obj = {
      name: product.name,
      id: product.id
    }
    this.modalController.dismiss(obj);
  }

  async search(ev){
    this._filters.search = ev;
    this._filters.pag = 1;
    this.products = [];
    this.infiniteScroll.setVisible();
  }
}
