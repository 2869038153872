import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-gift-section',
  templateUrl: './gift-section.component.html',
  styleUrls: ['./gift-section.component.scss'],
})
export class GiftSectionComponent implements OnInit {
  @Output() pricesSelected = new EventEmitter();
  public giftCard: any[] = undefined;

  constructor(
    private paramSv: ParamServicesService
  ) { }

  async ngOnInit() {
    this.giftCard = await this.paramSv.getGiftCards();
  }

  upload(){
    this.pricesSelected.emit(this.giftCard);
  }

}
