import { Injectable, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from './http.service';
import { UiServices } from './ui-services';

@Injectable({
  providedIn: 'root'
})
export class LoadService {
  public uploadData = new EventEmitter()
  public sended = new EventEmitter()

  constructor(
    private uiSv: UiServices,
    private httpSv: HttpService,
    private trSv: TranslateService
  ) { }

  async uploadZip(file, typeFile, type){
    try{
      await this.uiSv.showLoading();
      const formData = new FormData();
      formData.set('type', type);
      formData.set('masive', file);
      const res: any = await this.httpSv.itemAction(formData, typeFile, '');
      const errors = res.data.filter(item => !item.status);
      const uploaded = res.data.filter(item => item.status);
      await this.uiSv.loading.dismiss();
      return { errors, uploaded }
    }catch(err){
      console.error(err);
      await this.uiSv.loading.dismiss();
    }
  }

  async uploadExcel( excelData ){
    try{
      await this.uiSv.showLoading();
      const formData = new FormData();
      formData.set('file', excelData.file);
      formData.set('orden', excelData.orden);
      formData.set('line', excelData.line);
      await this.httpSv.itemAction(formData, 'masiveCsvWeb', '');
      await this.uiSv.loading.dismiss();
      const tr = this.trSv.instant('EL_ARCHIVO_SE_HA_SUBIDO_CON_EXITO');
      await this.uiSv.showToast(tr);
      this.sended.emit();
    }catch(err){
      console.error(err);
      const tr = this.trSv.instant('HA_OCURRIDO_UN_ERROR');
      await this.uiSv.loading.dismiss();
      await this.uiSv.showToast(tr);
    }
  }
}
