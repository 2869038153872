import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-gift-card-selection',
  templateUrl: './gift-card-selection.component.html',
  styleUrls: ['./gift-card-selection.component.scss'],
})
export class GiftCardSelectionComponent implements OnInit {
  @Input() card: any = undefined
  @Input() id: any = undefined

  constructor() { }

  ngOnInit() {}

}
