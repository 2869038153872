import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';

@Component({
  selector: 'app-slider-galeries',
  templateUrl: './slider-galeries.component.html',
  styleUrls: ['./slider-galeries.component.scss'],
})
export class SliderGaleriesComponent implements OnInit, AfterViewInit {
  @ViewChild('slideGallery') slideGallery: IonSlides;
  @Input() galleries: any[];
  @Input() data: any;
  public reachedEnd = false;
  public isStart = true;
  public slidesOpts = {
    breakpoints: {
      320: {
        slidesPerView: 1.1,
      },
      520: {
        slidesPerView: 2.1,
      },
      700: {
        slidesPerView: 3,
      },
      1270: {
        slidesPerView: 4,
      },
    }
  }

  constructor(
    private navCtrl: NavController
  ) { }

  ngOnInit() {}

  goGallery(gallery){
    this.navCtrl.navigateForward(`gallery/${gallery.id}`);
  }

  slideTo(type){
    type === 'next' ? this.slideGallery.slideNext(400) : this.slideGallery.slidePrev(400);
  }

  ngAfterViewInit(): void {
    setTimeout(async() => {
      this.reachedEnd = await this.slideGallery.isEnd();
    })
  }

  async changedSlide(ev){
    this.reachedEnd = ev.el.dom7ElementDataStorage.swiper.isEnd;
    if(!this.reachedEnd || this.reachedEnd){
      this.isStart = ev.el.dom7ElementDataStorage.swiper.isBeginning;
    }
  }
}
