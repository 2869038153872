import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-gift-card-home',
  templateUrl: './gift-card-home.component.html',
  styleUrls: ['./gift-card-home.component.scss'],
})
export class GiftCardHomeComponent implements OnInit {
  @Input() data = undefined;
  @Input() section: any[] = undefined;
  @ViewChild('slidesGiftCard') slidesGiftCard: IonSlides
  constructor(
    public loginSv: LoginService
  ) { }

  ngOnInit() {
  }

  async slideTo(direction){
    await direction ? this.slidesGiftCard.slideNext(400) : this.slidesGiftCard.slidePrev(400);
  }
  
}
