import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-categories-product-detail',
  templateUrl: './categories-product-detail.component.html',
  styleUrls: ['./categories-product-detail.component.scss'],
})
export class CategoriesProductDetailComponent implements OnInit {
  @Input() product : any;
  @ViewChild('containerChips') set containerChips(element){
    if(element){
      setTimeout(() => {
        if(element.nativeElement.clientHeight > 40){
          this.arrow = true
        }
      }, 500)
    }
  };
  public showCategories = false;
  public categories: any[] = [];
  public catalogueId = undefined;
  public arrow = false;
  public slidesOpts = {
    breakpoints: {
      320: {
        slidesPerView: 3.2
      },
      720: {
        slidesPerView: 6.2,
      },
      820: {
        slidesPerView: 8.2,
      },
      1280: {
        slidesPerView: 10.2,
      }
    },
  }

  constructor(
    private navCtrl: NavController,
  ) { }

  ngOnInit() {
    this.catalogueId = this.product.catalogueId;
    this.categories = this.product.categories;
  }


  async goCategory(id, parentId){
    const queryParams: any = {
      category: parentId || id
    }
    parentId && (queryParams.chCategory = id);
    await this.navCtrl.navigateForward(`catalogue/${this.catalogueId}`, { queryParams });
  }

  show(){
    this.showCategories = !this.showCategories;
  }
  
  trackByItems(index, item){
    return item.id
  }
}
