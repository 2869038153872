import { Component, Input, OnInit } from '@angular/core';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-container-card-product',
  templateUrl: './container-card-product.component.html',
  styleUrls: ['./container-card-product.component.scss'],
})
export class ContainerCardProductComponent implements OnInit {
  @Input() product: any = undefined;

  constructor(
    public paramSv: ParamServicesService
  ) { }

  ngOnInit() {
    
  }

}
