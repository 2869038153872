import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transcurredSeason'
})
export class TranscurredSeasonPipe implements PipeTransform {

  transform(season, init, end, transcurred, left): any {
    if(!season) return null
    if(init) return season.init;
    else if(end) return season.limitOrder || season.end;
    else if(transcurred){
      const endSeason = season.end;
      const initSeason = season?.init;
      const initSeasonToDate = new Date(initSeason).getTime();
      const actualDate = new Date().getTime();
      const endSeasonToDate = new Date(endSeason).getTime();

      //Dias transcurridos desde que comenzo el season
      const diffDayMs = actualDate - initSeasonToDate;
      const diffDay = diffDayMs / (1000 * 60 * 60 * 24);
      if(left){
        const actualDate = new Date(new Date().toISOString().split('T')[0]).getTime();
        const diffDayMs = endSeasonToDate - actualDate;
        const diffDay = diffDayMs / (1000 * 60 * 60 * 24);
        return Math.round( diffDay );
      }else{
        const daysOfSeason = (endSeasonToDate - initSeasonToDate )/ (1000 * 60 * 60 * 24);
        return `${(diffDay * 100) / daysOfSeason}%`;
      }
    }
  }

}
