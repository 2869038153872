import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  IonSlides,
  ModalController,
  NavController,
  Platform,
} from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { LoginService } from 'src/app/services/login.service';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { UiServices } from 'src/app/services/ui-services';
import { environment } from 'src/environments/environment';
import { ForgottenPasswordComponent } from '../forgotten-password/forgotten-password.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  @Output() register = new EventEmitter();
  @ViewChild('cardLogin') cardLogin: any;
  @ViewChild('loginSlides', { static: true }) loginSlides: IonSlides;
  public loginForm: FormGroup = undefined;
  public logo: string = undefined;
  public requiredAuth: boolean = false;
  public domainId = environment.domainId;
  public loginOpt: any[] = [];
  private routeObs: Subscription = undefined;

  constructor(
    private uiSv: UiServices,
    private loginService: LoginService,
    private fb: FormBuilder,
    private paramSv: ParamServicesService,
    private navCtrl: NavController,
    private trSv: TranslateService,
    private platform: Platform,
    private modalController: ModalController
  ) {}

  ngOnInit() {
    this.loginSlides.lockSwipes(true);


    this.requiredAuth = this.paramSv.requiredAuth;
    this.loginForm = this.fb.group({
      code: [
        '',
        [
          Validators.required,
          // Validators.pattern(/^[\w\.g]+@+[\w]+[.]+[\D]{2,10}$/)
        ],
      ],
      password: ['', [Validators.required]],
      canRemember: [false],
    });
    this.setLogo();
    this.loginOpt = this.paramSv.loginOptions?.social || [];
    this.configureOptions();
    const subsParam = this.paramSv.emitImagesObs.subscribe((item: any) => {
      if (item.name === 'login') {
        this.loginOpt = this.paramSv.loginOptions?.social;
        this.configureOptions();
      } else if (item.name === 'redirectTo') {
        this.requiredAuth = this.paramSv.requiredAuth;
      } else if (item === 'finish') {
        subsParam.unsubscribe();
      } else if (item.name === 'logo') {
        this.setLogo();
      }
    });
  }

  configureOptions() {
    if (this.platform.is('android')) {
      this.loginOpt = this.loginOpt.filter((x) => x.social !== 'Apple');
    }
  }

  ngOnDestroy(): void {
    this.routeObs && this.routeObs.unsubscribe();
  }

  setLogo() {
    const logoFt = this.paramSv.setLogo('Login');
    this.logo = logoFt;
  }

  async closeModal() {
    return await this.modalController.dismiss();
  }

  // async asGuess(){
  //   if(this.paramSv.loginOptions?.showAcceptModalExplore?.accept){
  //     const { data } = await this.uiSv.showModal(AcceptMessageComponent, {}, 'modalRegister');
  //     if(!data){
  //       return false;
  //     }
  //   }
  //   this.navCtrl.navigateBack('home');
  // }

  async signIn(social?) {
    try {
      this.loginForm.value.domainId = this.domainId;
      if (social === 'Google') {
        await this.loginService.loginGoogle(this.domainId);
        return true;
      } else if (social === 'Apple') {
        await this.loginService.loginApple(this.domainId);
        return true;
      }
      await this.closeModal();

      const regex = /^[\w\.g]+@+[\w]+[.]+[\D]{2,10}$/;
      const code = this.loginForm.value.code.trim();
      const email: any[] = regex.exec(code);
      if (email && email[0]) {
        this.loginForm.value.email = code;
        this.loginForm.value.code = '';
      }

      await this.loginService.signUp(this.loginForm.value, 'login');
      this.loginForm.reset();
    } catch (err) {
      if (typeof err.error === 'string') {
        this.uiSv.showToast(err.error);
      } else {
        const tr = this.trSv.instant('USUARIO_O_CONTRASEÑA_INCORRECTA');
        this.uiSv.showToast(tr);
      }
    }
  }

  async recovery() {
    try {
      await this.uiSv.showModal(ForgottenPasswordComponent, {}, 'forgottPass');
    } catch (err) {
      console.error(err);
    }
  }

  async signUp() {
    try {
      await this.navCtrl.navigateRoot('register');
      setTimeout(async () => {
        await this.closeModal();
      }, 500);
      // this.renderer.addClass(this.cardLogin.el, 'fadeOut');
      // setTimeout(() => {
      //   this.register.emit('register');
      // }, 500)
    } catch (err) {
      console.error(err);
    }
  }

  async forgotPassword(type) {
    await this.loginSlides.lockSwipes(false);
    type
      ? await this.loginSlides.slideNext(400)
      : await this.loginSlides.slidePrev(400);
    await this.loginSlides.lockSwipes(true);
    // await this.uiSv.showModal(ForgottenPasswordComponent, {}, 'modalRegister');
  }
}
