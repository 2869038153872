import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AffiliateService {
  private _mode = false;

  set mode(value){
    this._mode = value;
  }

  get mode(){
    return this._mode;
  }

  constructor() { }
}
