import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormServices } from 'src/app/services/form-services.service';
import { LoadService } from 'src/app/services/load.service';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-load-excel',
  templateUrl: './load-excel.component.html',
  styleUrls: ['./load-excel.component.scss'],
})
export class LoadExcelComponent implements OnInit, OnDestroy {
  @Output() emitData = new EventEmitter()
  private _domainId = environment.domainId;
  public colsQuantity = [];
  public selectedOptions = [];
  public selectableField = [];
  public selected = [];
  public line = true;
  public cols = 0;
  private _loadObs: Subscription = new Subscription();

  constructor(
    private formSv: FormServices,
    private paramSv: ParamServicesService,
    private loadSv: LoadService
  ) { }

  async ngOnInit() {

    this._loadObs.add(this.loadSv.uploadData.subscribe(() => {
      this.sendData();
    }));

    const existForm = await this.paramSv.existDistributorSignUpRes;
    let cols = [];
    let wholesale: any[] = [];
    const products: any[] = await this.formSv.getForm('products', this._domainId);
    const productosMas: any[] = await this.formSv.getForm('productosMas', this._domainId);
    const productosOptions: any[] = await this.formSv.getForm('productosOptions', this._domainId);
    if(existForm){
      wholesale = await this.formSv.getForm('wholesale', this._domainId);
    }

    if(products.length){
      products.map(item => {
        cols.push(
          {
            fieldName: item.fieldName,
            selected: false,
            label: item.labelValue,
          }
        );
      })
    }

    if(productosOptions.length){
      cols.push(
        {
          fieldName: 'codeParent',
          selected: false,
          label: 'Codigo padre',
        }
      );
    }



    if(productosMas.length){
      productosMas.map(item => {
        cols.push(
          {
            fieldName: item.fieldName,
            selected: false,
            label: item.labelValue,
          }
        );
      })
    }

    if(wholesale.length){
      wholesale.map(item => {
        cols.push(
          {
            fieldName: item.fieldName,
            selected: false,
            label: item.labelValue,
          }
        );
      })
    }

    this.selectableField = cols.filter(x => x.label.toLowerCase() !== 'imagen');
    const data = localStorage.getItem('excel');
    if(data){
      const parsedData = JSON.parse(data);
      this.line = parsedData.line;
      this.colsQuantity = parsedData.orden;
      this.selectedOptions = parsedData.orden;
      this.cols = this.colsQuantity.length;
    }
  }

  ngOnDestroy(): void {
    this._loadObs.unsubscribe();
  }

  sendData(){
    this.selectedOptions = this.selectedOptions.filter(item => item);
    const body = {
      orden: this.selectedOptions,
      line: this.line
    }
    this.emitData.emit(body);
  }

  dismark(ev){
    this.colsQuantity = [];
    this.selectableField.map(item => item.selected = false);
    setTimeout(() => {
      this.colsQuantity = new Array(Number(ev));
      this.selectedOptions = new Array(Number(ev));
    }, 100)
  }

  checkOptions(ev, i){
    const fieldName = ev.detail.value;
    if(this.selectedOptions[i] && this.selectedOptions[i] !== fieldName){
      const item = this.selectableField.find(item => item.fieldName === this.selectedOptions[i]);
      item && (item.selected = false);
    }
    const item = this.selectableField.find(item => item.fieldName === fieldName);
    item && (item.selected = true);
    this.selectedOptions[i] = fieldName;
  }
}
