import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit {
  @Input() set categories(value){
    this.categoriesData = value || [];
    this.checkSlide()
    // if(value){
    //   console.log(value);
    // }
  };

  @Input() selectedCategory: any;
  @Input() data: any;
  @ViewChild('slidesCategory') set slideElement(element){
    if(element){
      this._slidesCategory = element;
    }
  };
  @Output() emitCaegory = new EventEmitter();
  public categoriesData: any[] = [];
  private _slidesCategory: IonSlides
  public loaded = false;
  public reachedEnd = false;
  public isStart = true;
  public slideOpts: any = {
    breakpoints: {
      320: {
        slidesPerView: 2.1,
      },
      950: {
        slidesPerView: 3,
      },
      1024: {
        slidesPerView: 4,
      }
    }
  }

  constructor(
    public paramSv: ParamServicesService
  ) { }

  ngOnInit() {

    if(this.paramSv.typeCategory){
      this.setBreakpoints();
    }
    this.loaded = true;


    const param = this.paramSv.emitImagesObs.subscribe((item: any) => {
      if(item.name === 'categoryStyle'){
        this.loaded = false;
        this.setBreakpoints();
      }else if(item === 'finish'){
        param.unsubscribe();
        this.loaded = true;
      }
    })

  }

  checkSlide() {
    setTimeout(async() => {
      this.reachedEnd = await this._slidesCategory.isEnd();
    })
  }

  async changedSlide(ev){
    this.reachedEnd = ev.el.dom7ElementDataStorage.swiper.isEnd;
    if(!this.reachedEnd || this.reachedEnd){
      this.isStart = ev.el.dom7ElementDataStorage.swiper.isBeginning;
    }
  }

  setBreakpoints(){
    if(this.paramSv.typeCategory?.type === 'round'){
      const breakpoints = {
        520: {
          slidesPerView: 5.1,
        },
        950: {
          slidesPerView: 6,
        },
        1024: {
          slidesPerView: 7,
        },
        1200: {
          slidesPerView: 8,
        }
      }
      this.slideOpts.breakpoints = breakpoints;
    }
  }
  
  trackByItems(index, item){
    return item.id
  }

  async moveTo(direction){
    await direction === 'next' ? this._slidesCategory.slideNext(400) : this._slidesCategory.slidePrev(400);
  }
}
