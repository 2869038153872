import { Component, OnInit } from '@angular/core';
import { UiServices } from 'src/app/services/ui-services';

@Component({
  selector: 'app-container-wallet',
  templateUrl: './container-wallet.component.html',
  styleUrls: ['./container-wallet.component.scss'],
})
export class ContainerWalletComponent implements OnInit {
  public loaded = true;

  constructor(
    private uiSv: UiServices
  ) { }

  ngOnInit() {}

  async reloadHistory(){
    await this.uiSv.showLoading();
    this.loaded = false;
    setTimeout(async () => {
      this.loaded = true;
      await this.uiSv.loading.dismiss();
    }, 100)
  }
}
