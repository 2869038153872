import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { PasarelaPagoComponent } from 'src/app/components/pasarela-pago/pasarela-pago.component';
import { HttpService } from 'src/app/services/http.service';
import { LoginService } from 'src/app/services/login.service';
import { UiServices } from 'src/app/services/ui-services';
import { RechargeComponent } from '../recharge/recharge.component';

@Component({
  selector: 'app-balance-toolbar-payments',
  templateUrl: './balance-toolbar-payments.component.html',
  styleUrls: ['./balance-toolbar-payments.component.scss'],
})
export class BalanceToolbarPaymentsComponent implements OnInit {
  @Output() reloadHistory = new EventEmitter();
  public loading: boolean;
  public creditData: any;

  constructor(
    public loginSv: LoginService,
    private navCtrl: NavController,
    private httpSv: HttpService,
    private uiSv: UiServices
  ) { }

  ngOnInit(): void {
    this.getCredit();
  }

  async getCredit() {
    try{      
      this.loading = true;
      const data: any = await this.httpSv.itemIndex('credit/actualCredit');
      this.creditData = data.data;
      this.loading = false;
    }catch(err){
      console.error(err);
    }
  }

  goTo(url){
    this.navCtrl.navigateForward(`wallet/${url}`);
  }

  async refreshUserData(){
    try{
      this.getCredit();
    }catch(err){
      console.error(err);
    }
  }

  async pay(){
    try{
      const { data } = await this.uiSv.showModal(RechargeComponent, {type: 'payCredit'}, 'successComponent');
      if(data){
        const res = await this.uiSv.showModal(PasarelaPagoComponent, { goPayment: true, payment: data.ammount, transactionData: data }, 'pasarela-pago')
        if(res?.data?.text === 'sended'){
          this.reloadHistory.emit();
        }
      }    
    }catch(err){
      console.error(err);
    }
  }
}
