import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-show-terms',
  templateUrl: './show-terms.component.html',
  styleUrls: ['./show-terms.component.scss'],
})
export class ShowTermsComponent implements OnInit {

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() {}

}
