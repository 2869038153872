import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-catalogue',
  templateUrl: './catalogue.component.html',
  styleUrls: ['./catalogue.component.scss'],
})
export class CatalogueComponent implements OnInit {
  @Input() data: any;
  @Input() catalogues: any;
  url = environment.url;
  
  constructor(
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    
  }

  trackByItems(index, item){
    return item.id
  }

  goTo(url){
    this.navCtrl.navigateForward(url);
  }

}
