import { Pipe, PipeTransform } from '@angular/core';
import { HttpService } from '../services/http.service';

@Pipe({
  name: 'tax',
})
export class TaxPipe implements PipeTransform {
  constructor(private _httpSv: HttpService) {}

  async transform(value: number): Promise<number> {
    const packingTax = await this._httpSv.itemIndex('PackingTax', '', {
      pag: 1,
    });
    const data = packingTax?.data?.data[0] || 0;
    const amountTax = data.tax;
    return amountTax;
  }
}
