import { Injectable, EventEmitter } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  public months = [
    {
      month: "ENERO", 
      value: 0
    },
    {
      month: "FEBRERO", 
      value: 1
    },
    {
      month: "MARZO", 
      value: 2
    },
    {
      month: "ABRIL", 
      value: 3
    },
    {
      month: "MAYO", 
      value: 4
    },
    {
      month: "JUNIO", 
      value: 5
    },
    {
      month: "JULIO", 
      value: 6
    },
    {
      month: "AGOSTO", 
      value: 7
    },
    {
      month: "SEPTIEMBRE", 
      value: 8
    },
    {
      month: "OCTUBRE", 
      value: 9
    },
    {
      month: "NOVIEMBRE", 
      value: 10
    },
    {
      month: "DICIEMBRE",
      value: 11
    }
  ];

  public days = [
    {
      day: 'LUNES',
      value: 1
    },
    {
      day: 'MARTES',
      value: 2
    },
    {
      day: 'MIERCOLES',
      value: 3
    },
    {
      day: 'JUEVES',
      value: 4
    },
    {
      day: 'VIERNES',
      value: 5
    },
    {
      day: 'SABADO',
      value: 6
    },
    {
      day: 'DOMINGO',
      value: 0
    },
  ]

  public emitNewDate = new EventEmitter();

  constructor(
    private httpSv: HttpService
  ) { }

  convertData(){
    return new Promise<any[]>(async (resolve) => {
      const schedule = await this.httpSv.itemIndex('attentionSchedule');
      of(schedule).pipe(
        map(scheduleFilter => {
          const item: any[] = scheduleFilter.data.item;
          item.forEach(data => {
            data.day = data.day.split(',');
            data.day = data.day.map(item => Number(item));
            data.day = data.day.map(item => {
              const day = this.days.find(day => day.value === item);
              item = {
                day: day.day,
                item
              }
              return item;
            });
          })
          return item;
        })
      ).subscribe(data => {
        resolve(data);
      })
    })
  }

  compareSchedule(date, schedules){
    const onDate = new Date(date);
    const fixedHour = date.split('T')[1];
    let canStore = false;
    for (const item of schedules) {
      const days: any[] = item.day.split(',');
      const storeDay = onDate.getDay();
      const isInSchedule = days.find(item => Number(item) === storeDay);
      if(isInSchedule){
        if(fixedHour >= item.init && fixedHour <= item.end){
          canStore = true;
          break;
        }
      }else{
        canStore = false
      }
    }
    return canStore;
  }
}
