import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonSlides, ModalController } from '@ionic/angular';
import { LoginService } from 'src/app/services/login.service';
import { ParamServicesService } from 'src/app/services/param-services.service';
import { UiServices } from 'src/app/services/ui-services';
import { MultioComponent } from '../multio/multio.component';

@Component({
  selector: 'app-pasarela-pago',
  templateUrl: './pasarela-pago.component.html',
  styleUrls: ['./pasarela-pago.component.scss'],
})
export class PasarelaPagoComponent implements OnInit{
  @ViewChild('slides', { static: true }) slides: IonSlides;
  @ViewChild('content', { static: true }) content: IonContent;
  @Input() list: any = undefined;
  @Input() paramDispatch: any = undefined;
  @Input() total: number = undefined;
  @Input() goPayment: boolean = undefined;
  @Input() payment: number = undefined;
  @Input() transactionData: any = undefined;
  @Input() continueOrder: any = undefined;
  public multimonedaIcon: string = './assets/icons/moneda.svg';
  public data: any = undefined;
  public logoModal: any = undefined;
  public cart: string = '';
  public nameClient: string;
  
  constructor(
    public paramSv: ParamServicesService,
    private modalCtrl: ModalController,
    private loginSv: LoginService,
    private uiSv: UiServices
  ) {}

  ngOnInit(): void {
    if(this.goPayment){
      this.slides.slideNext();
      this.nameClient = this.loginSv.user.name;
    }
    if(this.continueOrder){
      this.data = this.continueOrder;
    }
    this.slides.lockSwipes(true);
    const modal = this.paramSv.setLogo('Modal');
    this.logoModal = modal;
  }

  nextPay(data){
    this.slides.lockSwipes(false);
    this.slides.slideNext(500);
    this.data = data.data;
    this.cart = data.cart;
    this.slides.lockSwipes(true);
    this.content.scrollToTop(500);
  }

  scrollToBottom(){
    setTimeout(() => {
      this.content.scrollToBottom(500);
    }, 250)
  }

  closeModal(ev?) {
    this.modalCtrl.dismiss(ev);
  }

  changeCoin(ev){
    this.uiSv.showPopover(MultioComponent, {}, '', ev)
  }
}
