import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpService } from './http.service';
import { UiServices } from './ui-services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class InteractiveService {
  public page = 1;
  public totalPages = 1;
  public interactiveCatalogueCoverId = undefined;
  private _actualPage = undefined;
  private _cart = [];
  private _total = 0;

  set total(value) {
    this._total = value;
  }

  get total() {
    return this._total;
  }

  set cart(value) {
    this._cart = value;
  }

  get cart() {
    return this._cart;
  }

  set actualPage(value) {
    this._actualPage = value;
  }

  get actualPage() {
    return this._actualPage;
  }

  constructor(
    private httpSv: HttpService,
    private route: ActivatedRoute,
    private uiSv: UiServices
  ) {}

  async getCatalogue(param, gridView?) {
    try {
      if (param) {
        param.interactiveCatalogueCoverId = this.interactiveCatalogueCoverId;
      }
      const res = await this.httpSv.itemIndex('interactiveImages', '', param);
      const data = res.data;
      const lastPage = data?.meta?.last_page;
      if (this.totalPages !== lastPage) {
        this.totalPages = lastPage || this.totalPages;
      }
      if (!param.pag) return res.data;
      return gridView ? data.data : data.data[0];
    } catch (err) {
      console.error(err);
    }
  }

  async addCart(product) {
    this.cart = this.cart.filter((x) => x.id !== product.id);
    this.cart.push(product);
    const price = product.promotionalPrice || product.price;
    this.total += price;
  }

  controleTotal(product, sum) {
    for (let i = 0; i < product.quantity; i++) {
      const price = product.promotionalPrice || product.price;
      this.total -= price;
    }
    if (this.total < 0) this.total = 0;
    return true;
  }

  async removeCart(index) {
    this.controleTotal(this.cart[index], false);
    this.cart[index].quantity = 0;
    this.cart.splice(index, 1);
  }

  async generate(name) {
    try {
      await this.uiSv.showLoading();
      const code = this.route.snapshot.queryParamMap.get('code');
      const body = {
        code,
        name,
        interactiveProduct: JSON.stringify(this.cart),
        domainId: environment.domainId,
      };
      const res = await this.httpSv.itemAction(
        body,
        'interactiveImages',
        'storeCart'
      );
      if (res.status) {
        await this.uiSv.loading.dismiss();
        for (const item of this.cart) {
          item.quantity = 0;
        }
        this.cart = [];
        await this.uiSv.presentAlert(
          'Su pedido ha sido enviado. Comuniquese con su vendedor',
          false,
          'Continuar'
        );
      }
      return true;
    } catch (err) {
      console.error(err);
    }
  }
}
