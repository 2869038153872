import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { UiServices } from 'src/app/services/ui-services';

@Component({
  selector: 'app-debit',
  templateUrl: './debit.component.html',
  styleUrls: ['./debit.component.scss'],
})
export class DebitComponent implements OnInit {
  @Input() data: any;
  public code: string = '';
  constructor(
    public modalController: ModalController,
    private httpSv: HttpService,
    private uiSv: UiServices
  ) { }

  ngOnInit() {
  }

  async sendCode(){
    try{
      await this.uiSv.showLoading();
      const res: any = await this.httpSv.itemAction({code: this.code, transactionId: this.data.transactionId}, 'admin/transaction', 'approve');
      await this.uiSv.showToast(res.data);
      res.status ? this.modalController.dismiss() : this.code = '';
      await this.uiSv.loading.dismiss();
    }catch(err){
      console.error(err);
    }
  }
}
