import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterResumeCart',
})
export class FilterResumeCartPipe implements PipeTransform {
  transform(value: any[]): any[] {
    return value.filter((product) => product.isActive);
  }
}
