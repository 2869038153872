import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'calcProgressReward'
})
export class CalcProgressRewardPipe implements PipeTransform {

  transform(points, userPoints, reedemeble?): string | boolean {
    // userPoints = Number(userPoints);
    // points = Number(points);
    const percent = (userPoints / points) * 100
    if(reedemeble) return percent < 100 ? true : false;
    return `${percent}%`;
  }

}
