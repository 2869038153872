import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  // private theStorage: Storage | null = null;
  saveCartSrc = new Subject();
  saveCartObs = this.saveCartSrc.asObservable();

  constructor(private storage: Storage) {
    this.storage.create();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    
    // this.theStorage = storage;
  }

  public async set(key: string, value: any) {
    
    return await this.storage?.set(key, value);
  }

  public async get(key: string) {
    return await this.storage?.get(key) || undefined;
  }

  public async remove(key: string) {
    return await this.storage?.remove(key);
  }

  public async clear() {
    return await this.storage?.clear();
  }

  getLocalStorage(key){
    const data = localStorage.getItem(key);
    return data;
  }

  setLocalStorage(key, value){
    localStorage.setItem(key, value);
  }
}
 