import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';
import { UiServices } from 'src/app/services/ui-services';
import { ActionClientComponent } from '../action-client/action-client.component';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
  public arr = new Array(20);
  public image = '/assets/admin/defaultImage.png';
  public slideOpt = {
    spaceBetween: 20,
    breakpoints: {
      320: {
        slidesPerView: 2
      },
      420: {
        slidesPerView: 4
      },
      520: {
        slidesPerView: 6
      },
      1280: {
        slidesPerView: 9 
      }

    }
  }
  public favoriteList: any[] = [];
  constructor(
    private httpSv: HttpService,
    private uiSv: UiServices,
    private navCtrl: NavController
  ) { }

  async ngOnInit() {
    try{
      const res: any = await this.httpSv.itemIndex('favorite');
      this.favoriteList = res.data;
    }catch(err){
      console.error(err);
    }
  }

  async openAction(user){
    const { data } = await this.uiSv.showPopover(ActionClientComponent);
    if(data){
      let queryParams: any = {
        ui: user.id
      };
      if(data === 'giftCard'){
        queryParams.giftCard = 1;
      }
      this.navCtrl.navigateForward(`wallet/transactions`, {queryParams});
    }
  }

}
