
import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { LayoutModule } from '@angular/cdk/layout';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ComponentsModuleModule } from './components/components-module.module';
import { Storage } from '@ionic/storage-angular';
import { FormsModule} from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es-AR';
import { registerLocaleData } from '@angular/common';
import { PipeModule } from './pipes/pipe-module.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat/';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { Stripe } from '@awesome-cordova-plugins/stripe/ngx';
import { GooglePlus } from '@awesome-cordova-plugins/google-plus/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateStore } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';
import { MenuSsrComponent } from './components/menu-ssr/menu-ssr.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeEs, 'es');


@NgModule({
    declarations: [
        AppComponent,
        MenuSsrComponent
    ],
    imports: [
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        IonicModule.forRoot(),
        AppRoutingModule,
        ComponentsModuleModule,
        LayoutModule,
        FormsModule,
        HttpClientModule,
        PipeModule,
        AngularFireModule.initializeApp(environment.firebaseConfig),
        AngularFireAuthModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: environment.production,
          // Register the ServiceWorker as soon as the application is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerWhenStable:30000'
        }),
    ],
    providers: [
        { provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        },
        Storage,
        { provide: LOCALE_ID, useValue: 'es' },
        Stripe,
        GooglePlus,
        SignInWithApple,
        TranslateStore,
        Meta,
        SocialSharing
    ],
    bootstrap: [AppComponent],
    exports: [
        MenuSsrComponent
    ]
})
export class AppModule {}
