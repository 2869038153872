import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { PasarelaPagoComponent } from 'src/app/components/pasarela-pago/pasarela-pago.component';
import { HttpService } from 'src/app/services/http.service';
import { LoginService } from 'src/app/services/login.service';
import { UiServices } from 'src/app/services/ui-services';
import { RechargeComponent } from '../recharge/recharge.component';

@Component({
  selector: 'app-balance-toolbar',
  templateUrl: './balance-toolbar.component.html',
  styleUrls: ['./balance-toolbar.component.scss'],
})
export class BalanceToolbarComponent implements OnInit {
  @Output() reloadHistory = new EventEmitter();
  public loading: boolean;

  constructor(
    public loginSv: LoginService,
    private navCtrl: NavController,
    private httpSv: HttpService,
    private uiSv: UiServices
  ) { }

  async ngOnInit() {
    try{
      const data: any = await this.httpSv.itemIndex('auth');
      await this.loginSv.saveUser(data.user);
    }catch(err){

    }
  }

  goTo(url, gift){
    let queryParams = {};
    if(gift){
      queryParams = {
        giftCard: 1
      }
    }
    this.navCtrl.navigateForward(`wallet/${url}`, {queryParams});
  }

  async refreshUserData(){
    try{
      this.loading = true;
      const data: any = await this.httpSv.itemIndex('auth');
      await this.loginSv.saveUser(data.user);
      this.loading = false;
    }catch(err){
      console.error(err);
    }
  }

  async recharge(){
    try{
      const { data } = await this.uiSv.showModal(RechargeComponent, {type: 'recharge'}, 'successComponent');
      if(data){
        const res = await this.uiSv.showModal(PasarelaPagoComponent, { goPayment: true, payment: data.ammount, transactionData: data }, 'pasarela-pago')
        if(res?.data?.text === 'sended'){
          this.reloadHistory.emit();
        }
      }
    }catch(err){
      console.error(err);
    }
  }
}
