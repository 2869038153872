import { Directive, Input, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appInsertDetail]'
})
export class InsertDetailDirective implements OnInit{
  @Input() set detail(value){
    if(value){
      this._description = value;
      this.setDescription();

    }
  };

  private _description = ''

  constructor( private el: ElementRef ) {}

  ngOnInit(): void {
  }

  setDescription(){
    const div: HTMLDivElement = this.el.nativeElement;
    const replacedDetail = this._description.replace(/\n/g, '</br>');
    div.innerHTML = `<span>${replacedDetail}</span>`;
  }
}
