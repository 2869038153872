import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-embed-video',
  templateUrl: './embed-video.component.html',
  styleUrls: ['./embed-video.component.scss'],
})
export class EmbedVideoComponent implements OnInit {
  @Input() video: string;

  constructor(
    public modalController: ModalController
  ) { }

  ngOnInit() {}

}
