import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss'],
})
export class MapsComponent implements OnInit, AfterViewInit {
  @Input() branch: any = undefined;
  @Input() i: number = 0;
  public resolution = window.matchMedia('(max-width: 578px)').matches;
  public directionTo = '';

  constructor() { }

  ngOnInit() {
    let b =  /src="(https:\/\/[^"]+)"/.exec(this.branch.maps);
    if(b){
      const map = b[1];
      const indexCoord = map.indexOf('!2d');
      const deleteStr = map.slice(0, indexCoord + 3);
      this.branch.maps = map;
      let slicedMap = map.replace(deleteStr, '');
      let contador = 0;
      for (let i = 0; i < slicedMap.length; i++) {
        if(contador === 2){
          contador = i;
          break;
        }else if(slicedMap[i] === '!'){
          contador++;
        }
      }
      const coords = slicedMap.slice(0, contador - 1).split('!3d');
      this.directionTo = `http://maps.google.com/maps?saddr=My+Location&daddr=${coords[1]},${coords[0]}`;
    }
  }

  ngAfterViewInit(): void {
    const element = document.querySelector(`.map${this.i}`);
    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', this.branch.maps);
    iframe.setAttribute('class', 'iframeMap');
    iframe.setAttribute('width', '100%');
    iframe.setAttribute('height', this.resolution ? '300' : '450');
    iframe.setAttribute('style', 'border:0; border-radius: 8px');
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('loading', 'lazy');
    iframe.setAttribute('referrerpolicy', 'no-referrer-when-downgrade');
    element.appendChild(iframe);
  }

  direction(){
    window.open(this.directionTo, '_blank');
  }
}
