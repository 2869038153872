import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-successfully-action',
  templateUrl: './successfully-action.component.html',
  styleUrls: ['./successfully-action.component.scss'],
})
export class SuccessfullyActionComponent implements OnInit {
  logo: string; 

  constructor(
    private paramSv: ParamServicesService,
    public modalController: ModalController
  ) { }

  async ngOnInit() {
    const modal = this.paramSv.setLogo('Modal');
    this.logo = modal;
  }

}
