import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';

@Component({
  selector: 'app-featured-gallery',
  templateUrl: './featured-gallery.component.html',
  styleUrls: ['./featured-gallery.component.scss'],
})
export class FeaturedGalleryComponent implements OnInit {
  @Input() gallery: any = undefined;
  @ViewChild('description', {static: true}) description: ElementRef<HTMLDivElement>;
  @ViewChild('slides', {static: true}) slides: IonSlides;
  public progress = 0;
  public progressTime = undefined;
  private intervalSlides = undefined;
 
  constructor(
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    const element = this.description.nativeElement;
    element.innerHTML = this.gallery?.description;
    if(element.innerText.length > 500){
      element.innerText = element.innerText.slice(0, 700);
      element.innerText += '...';
    }
    this.slides.lockSwipes(true);
  }

  trackByItems(index, item){
    return item.id
  }

  goGallery(){
    this.navCtrl.navigateForward(`gallery/${this.gallery.id}`);
  }

  async moveSlide(){
    const totalElements = await this.slides.length();
    const actualIndex = await this.slides.getActiveIndex();
    if((actualIndex + 1) === totalElements ){
      return;
    }

    await this.slides.lockSwipes(false);
    this.startProgress();
    if(this.intervalSlides){
      clearInterval(this.intervalSlides);
    }
    this.intervalSlides = setInterval(async () => {
      await this.slides.slideNext(400);
      const actualIndex = await this.slides.getActiveIndex();
      if((actualIndex + 1) === totalElements ){
        clearInterval(this.intervalSlides);
        this.progress = 0;
      }else{
        this.progress = 0;
        this.startProgress();
      }
    }, 3300)
  }

  startProgress(){
    try{
      let contador = 0;
      if(this.progressTime){
        clearInterval(this.progressTime);
      }
      this.progressTime = setInterval(() => {
        contador++;
        this.progress = contador / 100;
        if(contador > 100){
          if(this.progressTime){
            clearInterval(this.progressTime);
          }
        }
      }, 30);
    }catch(err){
      clearInterval(this.progressTime);
    }
  }

  async stopSlide(){
    await this.slides.stopAutoplay();
    await this.slides.slideTo(0);
    await this.slides.lockSwipes(true);
    clearInterval(this.progressTime);
    clearInterval(this.intervalSlides);
    this.progress = 0;
  }
}
