import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IonMenu, NavController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SplashComponent } from './components/splash/splash.component';
import { BalanceService } from './services/balance.service';
import { CartServiceService } from './services/cart-service.service';
import { HttpService } from './services/http.service';
import { LangService } from './services/lang.service';
import { LoginService } from './services/login.service';
import { MetadataService } from './services/metadata-service.service';
import { ParamServicesService } from './services/param-services.service';
import { PwaService } from './services/pwa.service';
import { StorageService } from './services/storage.service';
import { UiServices } from './services/ui-services';
import { NotificationsService } from './services/notifications.service';
import { VersionControllerService } from './services/version-controller.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('menu') ionMenu: IonMenu;

  public domainId = environment.domainId;
  public staticOpts: any[] = [
    {
      name: 'PROMOCIONES',
      icon: '/assets/icons/promos.svg',
      url: `marketplace?domainId=${this.domainId}&catalogue=&pag=1&search=&category=&orden=&promo=1&from=&upTo=`,
    },
    {
      name: 'LO_MAS_RECIENTE',
      icon: '/assets/icons/reciente.svg',
      url: `marketplace?domainId=${this.domainId}&catalogue=&pag=1&search=&category=&orden=newProducts&promo=&from=&upTo=`,
    },
    // {
    //   name: 'CAMBIAR_MONEDA',
    //   icon: '/assets/icons/moneda.svg',
    //   changeCurrency: true
    // },
    {
      name: 'MI_CUENTA',
      icon: '/assets/icons/Usuario.svg',
      url: 'profile',
    },
  ];

  public buttons = undefined;
  public colors: any[];
  public homeSections: any[];
  public subscriber: Subscription;

  constructor(
    private navCtrl: NavController,
    public paramSv: ParamServicesService,
    private elementRef: ElementRef,
    private router: Router,
    private uiSv: UiServices,
    private storageSv: StorageService,
    public loginSv: LoginService,
    private httpSv: HttpService,
    private cartSv: CartServiceService,
    public balanceSv: BalanceService,
    private pwaSv: PwaService,
    private metaSv: MetadataService,
    private langSv: LangService,
    private notificationSv: NotificationsService,
    @Inject(PLATFORM_ID) private platformId: any,
    private versionSv: VersionControllerService
  ) {
    this.versionSv.verifySw();
    this.storageSv.init();
    this.httpSv.getToken();
    this.cartSv.initCart();
    this.langSv.initLanguage();
    // this.metaSv.subscribeData();
  }

  async closeMenu() {
    await this.ionMenu.close(true);
  }

  async metaHome() {
    const res: any = await this.paramSv.getOnlyParam('seo');
    if (res) {
      const seoData = JSON.parse(res);
      this.paramSv.seo = seoData;
      this.metaSv.metaHome(seoData);
    }
  }

  async ngOnInit() {
    try {
      this.metaHome();
      if (!isPlatformBrowser(this.platformId)) {
        return false;
      }
      await this.loginSv.getTokenUser();
      if (this.loginSv.user) {
        this.cartSv.checkSeason();
      }
      this.notificationSv.subscribeLoginEvents();
      this.metaSv.subscribeData();
      this.pwaSv.pwa();
      await this.loginSv.getTokenUser();
      setTimeout(async () => {
        if (this.loginSv.token) {
          await this.loginSv.getUser();
        }
      });

      this.loginSv.emitLogin.subscribe(() => {
        this.cartSv.checkSeason();
        this.addOptions();
      });

      this.loginSv.emitLogout.subscribe(() => {
        this.addOptions();
      });

      await this.uiSv.showModal(SplashComponent, undefined, 'splash');

      this.colors = this.paramSv.colors;
      this.buttons = this.paramSv.buttons;
      this.homeSections = this.paramSv.homeSections;
      const fontFamily = this.paramSv?.styles?.find(
        (item) => item.text === 'Fuente'
      )?.selected;
      this.elementRef.nativeElement.style.setProperty(
        '--ion-font-family',
        `${fontFamily}, sans-serif`
      );

      let existCurrency: any = localStorage.getItem('currency');
      if (existCurrency === 'undefined') existCurrency = undefined;
      if (!existCurrency) {
        localStorage.setItem(
          'currency',
          JSON.stringify(this.paramSv.defaultCurrency)
        );
        this.balanceSv.currencySource.emit(this.paramSv.defaultCurrency);
      } else if (existCurrency) {
        let compareActualCurrency = JSON.parse(existCurrency);
        if (
          compareActualCurrency?.name === this.paramSv.defaultCurrency?.name
        ) {
          localStorage.setItem(
            'currency',
            JSON.stringify(this.paramSv.defaultCurrency)
          );
          this.balanceSv.currencySource.emit(this.paramSv.defaultCurrency);
        } else {
          const currencies: any[] = await this.balanceSv.getCurrencies();
          const updateCurrency = currencies.find(
            (item) => item.name === compareActualCurrency.name
          );
          localStorage.setItem('currency', JSON.stringify(updateCurrency));
          this.balanceSv.currencySource.emit(updateCurrency);
        }
      }
      await this.setColors();
      if (this.paramSv.requiredAuth) {
        this.subscriber = this.router.events
          .pipe(filter((event) => event instanceof NavigationEnd))
          .subscribe((event) => {
            const logined = localStorage.getItem('logined');
            if (logined === '1') {
              this.subscriber.unsubscribe();
            } else {
              this.navCtrl.navigateForward('auth');
            }
          });
      }
      window
        .matchMedia('(prefers-color-scheme: dark)')
        .addEventListener('change', (event) => {
          const valueButtons = this.buttons?.value;
          for (const key of Object.keys(valueButtons)) {
            const value = valueButtons[key];
            if (event.matches) {
              if (value?.darkBtnProperties) {
                for (const style of Object.keys(value?.darkBtnProperties)) {
                  this.elementRef.nativeElement.style.setProperty(
                    `--${key}${style}`,
                    value?.darkBtnProperties[style]
                  );
                }
              }
            } else {
              if (value?.lightBtnProperties) {
                for (const style of Object.keys(value?.lightBtnProperties)) {
                  this.elementRef.nativeElement.style.setProperty(
                    `--${key}${style}`,
                    value?.lightBtnProperties[style]
                  );
                }
              }
            }
          }

          this.colors.map((color) => {
            if (event.matches) {
              this.elementRef.nativeElement.style.setProperty(
                color.name,
                color.darkColor
              );
            } else {
              this.elementRef.nativeElement.style.setProperty(
                color.name,
                color.color
              );
            }
          });
          this.homeSections.map((section) => {
            if (event.matches) {
              this.elementRef.nativeElement.style.setProperty(
                `--${section.type}`,
                section.darkBackgound
              );
            } else {
              this.elementRef.nativeElement.style.setProperty(
                `--${section.type}`,
                section.backgound
              );
            }
          });
        });
      await this.loginSv.comprobeKit();
      this.addOptions();
    } catch (err) {
      console.error(err);
    }
  }

  // async comprobeLogin(){
  //   const existLogin = !this.staticOpts.find(item => item.url === 'auth');
  //   if(existLogin){
  //     this.staticOpts.unshift({
  //       name: 'INICIAR_SESION',
  //       icon: '/assets/icons/home-outline.svg',
  //       url: 'auth'
  //     });
  //   }
  // }

  addOptions() {
    if (
      this.paramSv.ableLogin === undefined ||
      this.paramSv.ableLogin?.canLogin
    ) {
      if (this.loginSv.user) {
        if (this.loginSv.user.rolId === 3) {
          this.staticOpts.unshift({
            name: 'ADMINISTRADOR',
            icon: '/assets/icons/administrador.svg',
            url: 'admin',
          });
        }
        if (!this.staticOpts.find((x) => x.name === 'SALIR')) {
          this.staticOpts.push({
            name: 'SALIR',
            icon: '/assets/icons/salir.svg',
            url: 'logout',
          });
        }
      } else {
        this.staticOpts = this.staticOpts.filter(
          (x) => x.name !== 'SALIR' && x.name !== 'ADMINISTRADOR'
        );
      }
    }
  }
  // }

  // comprobeAdminOption(){
  //   this.staticOpts = this.staticOpts.filter(item => item.url !== 'admin' && item.url !== 'operator');

  setColors() {
    return new Promise((resolve) => {
      const matches = window.matchMedia('(prefers-color-scheme: dark)').matches;
      const valueButtons = this.buttons?.value;
      if (valueButtons) {
        for (const key of Object.keys(valueButtons)) {
          const value = valueButtons[key];
          if (matches) {
            if (value?.darkBtnProperties) {
              for (const style of Object.keys(value?.darkBtnProperties)) {
                this.elementRef.nativeElement.style.setProperty(
                  `--${key}${style}`,
                  value?.darkBtnProperties[style]
                );
              }
            }
          } else {
            if (value?.lightBtnProperties) {
              for (const style of Object.keys(value?.lightBtnProperties)) {
                this.elementRef.nativeElement.style.setProperty(
                  `--${key}${style}`,
                  value?.lightBtnProperties[style]
                );
              }
            }
          }
        }
      }

      this.homeSections.forEach((section) => {
        if (matches) {
          this.elementRef.nativeElement.style.setProperty(
            `--${section.type}`,
            section.darkBackgound
          );
        } else {
          if (section?.backgound) {
            this.elementRef.nativeElement.style.setProperty(
              `--${section.type}`,
              section.backgound
            );
          }
        }
      });

      this.colors.forEach((color) => {
        if (matches) {
          this.elementRef.nativeElement.style.setProperty(
            color.name,
            color.darkColor
          );
        } else {
          this.elementRef.nativeElement.style.setProperty(
            color.name,
            color.color
          );
        }
      });

      this.elementRef.nativeElement.style.setProperty(
        '--ion-color-primary',
        'var(--primary--background)'
      );
      resolve(true);
    });
  }

  trackByItems(index, item) {
    return item.id ? item.id : item.name;
  }
}
