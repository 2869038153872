import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fixStyle'
})
export class FixStylePipe implements PipeTransform {

  transform(value: string): string {
    const style = `var(--${value})`;
    return style;
  }

}
