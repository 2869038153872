import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-sepecial-buttons',
  templateUrl: './sepecial-buttons.component.html',
  styleUrls: ['./sepecial-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SepecialButtonsComponent implements OnInit {
  @ViewChild('sliderButton') slider: IonSlides;
  @Input() specialButton: any = undefined;
  @Input() data: any = undefined;
  public slideOpts = {
    breakpoints: {
      320: {
        slidesPerView: 2.1,
      },
      950: {
        slidesPerView: 3.1,
      },
      1024: {
        slidesPerView: 4.1,
      }
    }
  }
  public buttons: any[] = [];
  public reachedEnd = false;
  public isStart = true;
  public typeButton: string = undefined;

  constructor(
    private navCtrl: NavController,
    private paramSv: ParamServicesService,
    private cdr: ChangeDetectorRef
  ) { }

  async ngOnInit() {
    const dataStyle: string = await this.paramSv.getOnlyParam('buttonStyle');
    if(dataStyle){
      const buttonStyle = JSON.parse(dataStyle);
      this.typeButton = buttonStyle.type;
    }
    const data = JSON.parse(this.specialButton.value);
    this.buttons = data;
    this.cdr.detectChanges();
  }

  slideTo(pos?){
    (pos === 'next') ? this.slider.slideNext() : this.slider.slidePrev();
  }

  openRoute(route){
    const type = !!route.includes('https');
    if(type){
      window.open(route, '_blank');
    }else{
      this.navCtrl.navigateForward(route);
    }
    return true;
  }

  ngAfterViewInit(): void {
    setTimeout(async() => {
      this.reachedEnd = await this.slider.isEnd();
      this.cdr.detectChanges();
    })
  }

  async changedSlide(ev){
    this.reachedEnd = ev.el.dom7ElementDataStorage.swiper.isEnd;
    if(!this.reachedEnd || this.reachedEnd){
      this.isStart = ev.el.dom7ElementDataStorage.swiper.isBeginning;
      this.cdr.markForCheck();
    }
  }
}
