import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-searchbar',
  templateUrl: './searchbar.component.html',
  styleUrls: ['./searchbar.component.scss'],
})
export class SearchbarComponent implements OnInit {
  @Output() searchEmit = new EventEmitter();
  @Output() setSearch = new EventEmitter();
  @Input() placeholder = '¿Qué estás buscando?';
  @Input() searchWithKeywords = false;
  @Input() boxShadow = '0 2px 10px rgb(0 0 0 / 30%)';
  @Input() borderRadius = '2rem';
  @Input() padding = '';
  @Input() debounce = 0;
  public search = '';
  public searchIcon: string = './assets/icons/buscador.svg';
  public coincidences: any[] = [];
  private _domainId = environment.domainId;

  constructor(
    private httpSv: HttpService
  ) { }

  ngOnInit() {
    if(this.searchWithKeywords){
      this.debounce = 250
    }
  }

  async onSearchChange(ev){
    this.search = ev.detail.value;
    this.setSearch.emit(this.search);
    if(this.searchWithKeywords){
      const res: any = await this.httpSv.searchWithKeywords({ domainId: this._domainId, search: this.search })
      this.coincidences = res.data;
      return true;
    }
  }

  tapSearch(name){
    this.coincidences = [];
    this.search = '';
    this.setSearch.emit(name);
    this.doSearch();
  }

  doSearch(value?){
    if(value){
      this.setSearch.emit(value);
    }
    this.searchEmit.emit();
  }
}
