import { Component, Input, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss'],
})
export class SponsorsComponent implements OnInit {
  @Input() sponsors;

  sponsorImages: any[] = [];
  fixedSponsor: any = {};
  slideOpts = {
    spaceBetween: 20,
    breakpoints: {
      320: {
        slidesPerView: 2.2,
      },
      420: {
        slidesPerView: 3.2,
      },
      520: {
        slidesPerView: 4.2,
      },
      800: {
        slidesPerView: 5.2,
      },
    }
  }

  constructor(
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.fixedSponsor = this.sponsors.sponsor;
  }

  goSponsor(sponsor){
    if(sponsor.galleriesCount || sponsor.productsCount){
      this.navCtrl.navigateForward(`sponsors/${sponsor.id}`);
    }
  }

}
