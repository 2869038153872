import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { StorageService } from 'src/app/services/storage.service';
import { environment } from 'src/environments/environment';
import { BalanceService } from './balance.service';
import { HttpService } from './http.service';
import { LoginService } from './login.service';
import { ParamServicesService } from './param-services.service';
import { UiServices } from './ui-services';

@Injectable({
  providedIn: 'root',
})
export class CartServiceService {
  private _domainId = environment.domainId;
  public marketplaceFilers: any = {
    domainId: this._domainId,
    catalogue: '',
    pag: 1,
    search: '',
    category: '',
    orden: '',
    promo: '',
    from: '',
    upTo: '',
    sponsor: '',
    services: '',
    subscription: '',
  };
  public next = new EventEmitter();
  public refreshCart = new EventEmitter();
  public cart: any[] = [];
  public total: any = 0;
  public totalQuantity = 0;
  public totalSrc = new Subject();
  public totalSrcObs = this.totalSrc.asObservable();
  public logined = false;
  public dist = undefined;
  public client = undefined;
  public canBuy = true;
  public season = undefined;

  constructor(
    private storageService: StorageService,
    private uiServices: UiServices,
    private httpSv: HttpService,
    private loginSv: LoginService,
    private balanceSv: BalanceService,
    private paramSv: ParamServicesService,
    private trSv: TranslateService
  ) {}

  initCart() {
    this.logined = localStorage.getItem('logined') === '1' ? true : false;
    this.setRoll();
    this.storageService.saveCartObs.subscribe((item) => {
      if (item === 'logout') {
        this.total = 0;
        this.cart = [];
        return true;
      }
      this.logined = true;
      this.getCart(false);
    });
    this.getCart(false);
  }

  setRoll() {
    const subs = this.loginSv.isLogined.subscribe(() => {
      const rollId = this.loginSv.user?.rolId || undefined;
      this.dist =
        rollId === 7 || (this.paramSv.existDistributorSignUp && rollId === 3);
      this.client =
        (rollId !== 7 && rollId !== 3) ||
        (rollId === 3 && !this.paramSv.existDistributorSignUp);
      subs.unsubscribe();
    });
  }

  clearFilter() {
    for (const key in this.marketplaceFilers) {
      if (key === 'pag') {
        this.marketplaceFilers[key] = 1;
      } else if (key === 'domainId') {
        this.marketplaceFilers[key] = this._domainId;
      } else {
        this.marketplaceFilers[key] = '';
      }
    }
  }

  async compareCart(emitLength) {
    return new Promise<any[]>(async (resolve) => {
      if (!this.logined) {
        await this.fillCart();
        this.cart = this.cart.map((item) => {
          if (item.isActive) {
            item.isChecked = true;
          } else if (item.meetingId) {
            item.isChecked = true;
          }
          return item;
        });
        resolve(this.cart);
      } else {
        this.httpSv.loadingIndicatorSrc.next(true);
        const res: any = await this.httpSv.itemIndex('cart');
        let savedCart: any[] = res.data;
        savedCart = savedCart.filter(
          (item) => item.productId || item.meetingId || item.subscriptionId
        );
        setTimeout(async () => {
          savedCart = this.activeProduct(savedCart);
          if (this.paramSv.existDistributorSignUp) {
            savedCart = this.activeProduct(savedCart);
          } else {
            const subs = this.paramSv.emitImagesObs.subscribe((item: any) => {
              if (item.name === 'distributor') {
                savedCart = this.activeProduct(savedCart);
                subs.unsubscribe();
              }
            });
          }

          await this.storageService.set('cart', savedCart);
          this.cart = savedCart;
          await this.getCart(emitLength);
          this.httpSv.loadingIndicatorSrc.next(false);
          this.cart = savedCart;
          resolve(savedCart);
        });
      }
    });
  }

  async checkSeason() {
    const res = await this.httpSv.itemIndex(
      'season/showId',
      environment.domainId,
      '',
      ''
    );
    if (!res.data) {
      this.canBuy = false;
      return true;
    }

    this.season = res.data;
    const limitOrder = this.season.limitOrder || this.season.end;

    const limitOrderToDate = new Date(limitOrder);
    const actualDate = new Date();
    if (actualDate > limitOrderToDate) {
      this.canBuy = false;
    }
  }

  activeProduct(savedCart) {
    const rolId = this.loginSv?.user?.rolId;
    const dist =
      rolId === 7 || (this.paramSv.existDistributorSignUp && rolId === 3);
    savedCart.map((item) => {
      let comparePriceDist =
        item.product?.promotionalPriceWSale || item.product?.priceWSale;
      let comparePriceClient =
        item.product?.promotionalPrice || item.product?.price;
      if (item?.product && item.productId) {
        if (item.cartProductOption?.length) {
          for (const option of item.cartProductOption) {
            comparePriceDist && (comparePriceDist += option.price);
            comparePriceClient && (comparePriceClient += option.price);
          }
        }
        if (dist) {
          if (
            (item.product.existenceWSale === 0 ||
              item.product.existence === 0) &&
            !item.product.countHidden
          ) {
            item.isActive = 0;
            this.noExistenceMessage(item);
          } else {
            item.isActive = 1;
          }
          const comparePrice = comparePriceDist || comparePriceClient;
          this.comparePrices(item, comparePrice);
          // if((comparePriceDist && item.price !== comparePriceDist) || (comparePriceClient && item.price !== comparePriceClient)){
          //   item.message = `El precio ha cambiado, precio anterior: ${item.price} ${this.balanceSv.currency?.symbol || '$'}`;
          //   item.price = comparePriceDist || comparePriceClient;
          //   item.changedPrice = true;
          // }
        } else {
          if (item.product.existence === 0 && !item.product.countHidden) {
            item.isActive = 0;
            this.noExistenceMessage(item);
          } else {
            item.isActive = 1;
          }
          this.comparePrices(item, comparePriceClient);
          // if(comparePriceClient && item.price !== comparePriceClient){
          //   item.message = `El precio ha cambiado, precio anterior: ${item.price} ${this.balanceSv.currency.symbol}`;
          //   item.price = comparePriceClient;
          //   item.changedPrice = true;
          // }
        }
      } else if (item.subscriptionId) {
        item.isActive = 1;
        this.comparePrices(item, comparePriceClient);
        // if(comparePriceClient && item.price !== comparePriceClient){
        //   item.message = `El precio ha cambiado, precio anterior: ${item.price} ${this.balanceSv.currency.symbol}`;
        //   item.price = comparePriceClient;
        //   item.changedPrice = true;
        // }
      } else {
        if (item.existence === 0 && !item.countHidden) {
          item.isActive = 0;
          this.noExistenceMessage(item);
        } else {
          item.isActive = 1;
        }
      }
      if (item.isActive) item.isChecked = true;
      else if (item.meetingId) {
        item.isChecked = true;
        item.isActive = 1;
      }
    });
    return savedCart;
  }

  comparePrices(item, comparePrice) {
    if (comparePrice && item.price !== comparePrice) {
      const msg = this.trSv.instant('EL_PRECIO_HA_CAMBIADO_PRECIO_ANTERIOR');
      item.message = `${msg}: ${item.price} ${
        this.balanceSv.currency?.symbol || 'USD'
      }`;
      item.price = comparePrice;
      item.changedPrice = true;
    }
  }

  fillCart() {
    return new Promise(async (resolve) => {
      setTimeout(async () => {
        if (!this.cart.length) {
          this.cart = (await this.storageService.get('cart')) || [];
        }
        resolve(this.cart);
      }, 100);
    });
  }

  async setCart(product?) {
    await this.fillCart();
    if (!product) {
      return;
    }
    if (!this.logined) {
      const cart = this.cart.filter((item) => item.id !== product[0]?.id);
      this.cart = cart;
      this.cart.push(...product);
    } else {
      this.cart = [...product];
    }

    const rolId = this.loginSv?.user?.rolId;
    const dist =
      rolId === 7 || (this.paramSv.existDistributorSignUp && rolId === 3);
    this.cart.map((item) => {
      if (item.product && item.productId && rolId) {
        if (dist) {
          if (item.existenceWSale === 0 && !item.countHidden) {
            item.isActive = 0;
            this.noExistenceMessage(item);
          } else {
            item.isActive = 1;
          }
        } else {
          if (item.existence === 0 && !item.countHidden) {
            item.isActive = 0;
            this.noExistenceMessage(item);
          } else {
            item.isActive = 1;
          }
        }
      } else {
        if (item.existence === 0 && !item.countHidden) {
          item.isActive = 0;
          this.noExistenceMessage(item);
        } else {
          item.isActive = 1;
        }
      }
    });

    await this.storageService.set('cart', this.cart);
    return true;
  }

  noExistenceMessage(item) {
    item.noExistence =
      'El producto no esta disponible en estos momentos. No será registrado en tu compra';
  }

  setLength(emit = true) {
    setTimeout(() => {
      this.total = 0;
      this.totalQuantity = 0;

      this.cart?.map((item) => {
        if (item?.productId || item?.id) {
          this.total += item.quantity;
          let totalQuantityProduct = 0;
          for (let i = 0; i < item.quantity; i++) {
            totalQuantityProduct += item.price;
          }
          item.isActive && (this.totalQuantity += totalQuantityProduct);
        }
      });

      if (this.total > 99) {
        this.total = '99+';
      }
      this.totalSrc.next({ emit, total: this.total });
    }, 100);
  }

  async findValue(id) {
    await this.fillCart();
    let product = undefined;
    if (!this.cart) return null;
    if (!this.logined) {
      product = this.cart?.filter((x) => {
        if (
          (x.serviceId && x.serviceId === id) ||
          (x.subscriptionId && x.subscriptionId === id) ||
          (x.id && x.id === id)
        ) {
          return x;
        }
      });
    } else {
      product = this.cart?.filter((x) => {
        if (
          (x.serviceId && x.serviceId === id) ||
          (x.subscriptionId && x.subscriptionId === id) ||
          (x.productId && x.productId === id)
        ) {
          return x;
        }
      });
    }
    return product;
  }

  async countProducts(op: string, cantidad: any) {
    if (op === 'sum') {
      cantidad++;
    } else if (op === 'rest' && cantidad > 0) {
      cantidad--;
    }
    return { cantidad };
  }

  async addCart(product, doAdd = true, isDistributor?, setLength?) {
    try {
      let isSubscription = false;
      await this.uiServices.showLoading();
      let media = undefined;
      if (product.media) {
        media =
          typeof product.media === 'string'
            ? product?.media
            : product?.media[0]?.file;
      }
      if ('inscriptionPrice' in product) {
        product.subscriptionId = product.id;
        const subscriptionPrice = product.promotionalPrice || product.price;
        product.price = product.inscriptionPrice || subscriptionPrice;
        isSubscription = true;
      } else {
        !product.productId && (product.productId = product.id);
      }
      const findProduct: any = await this.findValue(product.id);
      if (findProduct && doAdd) {
        const { detail, quantity } = await this.verifyIsSameProduct(
          product,
          findProduct
        );
        product.quantity = quantity;
        product.detail = detail;
      }
      const saveProduct = {
        ...product,
      };

      saveProduct.media = media;

      if (isDistributor) {
        saveProduct?.priceWSale && (saveProduct.price = saveProduct.priceWSale);
        saveProduct?.promotionalPriceWSale &&
          (saveProduct.price = saveProduct.promotionalPriceWSale);
      } else {
        saveProduct?.promotionalPrice &&
          (saveProduct.price = saveProduct.promotionalPrice);
      }

      if (findProduct && this.logined && !doAdd && !isSubscription) {
        delete saveProduct.id;
      } else {
        delete saveProduct.productId;
      }

      // let data = [saveProduct];

      if (this.logined) {
        await this.httpSv.itemAction(saveProduct, 'cart', 'update');
        // data = res.data;
      }

      if (doAdd && !isSubscription) {
        delete saveProduct.productId;
        // this.totalSrc.next();
      }

      // await this.setCart(data);

      this.activeProduct(this.cart);
      this.setLength(setLength);
      await this.compareCart(false);
      await this.uiServices.loading.dismiss();
      return product;
    } catch (err) {
      console.error(err);
      await this.uiServices.loading.dismiss();
    }
  }

  async reedem(reedem) {
    try {
      const body = {
        redeemableId: reedem.id,
        userId: this.loginSv.user.id,
        date: new Date(),
      };
      const res = await this.httpSv.itemAction(body, 'redeemables', 'claim');
      if (res.status) {
        await this.uiServices.showToast('Reclamado');
      } else {
        await this.uiServices.showToast('No tienes puntos suficientes.');
      }
    } catch (err) {
      console.error(err);
    }
  }

  async getCart(emit?) {
    try {
      const cart = await this.fillCart();
      const res: any = await this.httpSv.itemAction(
        { items: cart },
        'cart',
        'store'
      );
      await this.setCart(res.data);
      this.setLength(emit);
    } catch (err) {
      console.error(err);
    }
  }

  async verifyIsSameProduct(product, findProducts: any[]) {
    let quantity = product.quantity;
    let detail = product.detail;

    for (const findProduct of findProducts) {
      const data = [];
      product.additionals?.forEach((item) => {
        data.push(...item.checkedAdds);
      });
      const productOptions = data.map((item) => item.name);
      if (findProduct.cartProductOption) {
        findProduct.cartProductOption = findProduct.cartProductOption.map(
          (item) => item.name
        );
      } else if (!this.logined) {
        const data = [];
        findProduct.additionals?.forEach((item) => {
          data.push(...item.checkedAdds);
        });
        findProduct.cartProductOption = data.map((item) => item.name);
      }
      const compareSaved =
        JSON.stringify(findProduct.cartProductOption) || '[]';
      const compareProduct = JSON.stringify(productOptions) || '[]';

      if (compareSaved === compareProduct) {
        product.same = true;
        quantity += findProduct.quantity;
        detail && (product.detail += findProduct.detail);
        break;
      } else {
        product.same = false;
      }
    }
    return { quantity, detail };
  }

  async deleteCart(cartId) {
    try {
      const res: any = await this.httpSv.itemAction(
        { cartId },
        'cart',
        'destroyCart'
      );
      await this.setCart(res.data);
      this.setLength();
    } catch (err) {
      console.error(err);
    }
  }

  async saveCart(cart, item?) {
    await this.storageService.set('cart', cart);
    this.cart = cart;
    // if(!this.logined) this.total -= item.quantity;
    return true;
  }

  async favoriteControl(productId, action) {
    try {
      const res = await this.httpSv.itemAction(
        { productId },
        'productFavorite',
        action
      );
      return res.data;
    } catch (err) {
      console.error(err);
    }
  }

  async agendDate(date) {
    try {
      const res: any = await this.httpSv.itemAction(date, 'cart', 'update');
      await this.setCart(res.data);
      await this.activeProduct(this.cart);
      this.setLength();
    } catch (err) {
      console.error(err);
    }
  }

  async verifyExistMeet() {
    try {
      const meetings = this.cart.filter((item) => item.meetingId);
      const body = {
        ids: meetings.map((item) => item.meetingId),
      };
      const res = await this.httpSv.itemAction(body, 'cart', 'isAvailable');
      const errors: any[] = res.data;
      if (errors.length) {
        const trSv = this.trSv.instant('LOS_SERVICIOS');
        let errorsMsg = `${trSv}: `;
        for (const meet of errors) {
          const meetingsError = this.cart.find(
            (item) => item.meetingId === meet
          );
          errorsMsg += `${meetingsError.name}\n\n`;
          const id = meetingsError.id;
          const cartId = meetingsError.cartId;
          await this.httpSv.itemAction({ id, cartId }, 'cart', 'destroy');
        }
        await this.compareCart(false);
        const trPr = this.trSv.instant(
          'NO_PUEDE_PROCESARSE_DEBIDO_QUE_LOS_HORARIOS_SELECCIONADOS_YA_FUERON_OCUPADOS_POR_FAVOR_SELECCIONA_OTROS_HORARIOS'
        );
        errorsMsg += trPr;
        await this.uiServices.presentAlert(errorsMsg, false);
        this.marketplaceFilers.services = 1;
        return false;
      } else {
        return true;
      }
    } catch (err) {}
  }

  calcPriceOptions(data, ev) {
    const restPrice = ev.restPrice;
    const addPrice = ev.price;
    data.price && ((data.price -= restPrice), (data.price += addPrice));
    data.promotionalPrice &&
      ((data.promotionalPrice -= restPrice),
      (data.promotionalPrice += addPrice));
    data.priceWSale &&
      ((data.priceWSale -= restPrice), (data.priceWSale += addPrice));
    data.promotionalPriceWSale &&
      ((data.promotionalPriceWSale -= restPrice),
      (data.promotionalPriceWSale += addPrice));
  }

  verifyFavorite(data) {
    return data.favorite ? 'heart' : 'heart-outline';
  }

  async addFavorite(data) {
    try {
      if (!this.loginSv.user) {
        const txt = this.trSv.instant(
          'DEBES_INICIAR_SESION_PARA_AGREGAR_PRODUCTOS_A_FAVORITOS'
        );
        await this.uiServices.presentAlert(txt, true);
      } else {
        let action = '';
        data.favorite ? (action = 'destroy ') : (action = 'store');
        await this.favoriteControl(data.id, action);
        const favoriteIcon = action === 'store' ? 'heart' : 'heart-outline';
        const favorite = action === 'store' ? 1 : 0;
        return { favoriteIcon, favorite };
      }
    } catch (err) {
      console.error(err);
    }
  }
}
