import { Pipe, PipeTransform } from '@angular/core';
import { CartServiceService } from '../services/cart-service.service';

@Pipe({
  name: 'calcPoints'
})
export class CalcPointsPipe implements PipeTransform {

  constructor(private cartSv: CartServiceService){}

  transform(value: any[], pointsParam: any, season, test): Promise<number> {
    return new Promise(resolve => {
      if(!value.length) resolve( 0 );
      if(!season){
        setTimeout(async () => {
          const points = await value.reduce( (acc, next) => { 
            if(!next.isChecked) return acc += 0;
    
            const quantity = next.quantity || next.product?.quantity;
            return acc += (next.points || next.product.points) * quantity;
          }, 0)

          resolve(points);
        })

        return;

      }
  
      const limitOrder = season.init;
      const limitOrderToDate = new Date(limitOrder).getTime();
      const actualDate = new Date().getTime();
      const diffDayMs = actualDate - limitOrderToDate;
      const diffDay = diffDayMs / (1000 * 60 * 60 * 24);
  
      setTimeout(async () => {
        const point = await value.reduce( (acc, next) => {
          if(!next.isChecked) return acc += 0;
    
          const quantity = next.quantity || next.product?.quantity;
          const points = (next.points || next.product.points) * quantity;
          const increase = this.comprobeDate(diffDay, pointsParam);
          if(increase && next.product.commission != 0 && this.cartSv.canBuy) {
            return acc += ( points * increase ) / 100;
          };
          
          return acc += points;
        },0)
        resolve( point );

      })

    })
  }

  comprobeDate(actualDay, pointsParam){
    if(actualDay >= pointsParam?.initPoint && actualDay <= pointsParam?.daysPoint){
      return pointsParam?.increasePoint;
    }else if(actualDay >= pointsParam?.daysPoint && actualDay <= pointsParam?.endPoint){
      return pointsParam?.endIncreasePoint;
    }else{
      return false
    }
    
  }

}
