import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-initial-kit',
  templateUrl: './initial-kit.component.html',
  styleUrls: ['./initial-kit.component.scss'],
})
export class InitialKitComponent implements OnInit {
  public availableKits = [];

  constructor(
    private httpSv: HttpService,
    public modalController: ModalController
  ) { }

  async ngOnInit() {
    try{
      const res = await this.httpSv.itemIndex('product/kit');
      this.availableKits = res.data.data;
    }catch(err){
      console.error(err);
      
    }
  }

}
