import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findSeason'
})
export class FindSeasonPipe implements PipeTransform {

  transform(seasons: any[], seasonId, reedem?): string | boolean {
    const season = seasons.find(season => season.id === seasonId) || seasons[0];
    if(reedem){
      const actualDate = new Date();
      const endDate = new Date(season?.end);
      if(actualDate >= endDate){
        return false
      }else{
        return true
      }
    }
    return season?.end;
  }

}
