import { Pipe, PipeTransform } from '@angular/core';
import { CartServiceService } from '../services/cart-service.service';
import { HttpService } from '../services/http.service';

@Pipe({
  name: 'calcTotalWithComission',
})
export class CalcTotalWithComissionPipe implements PipeTransform {
  constructor(
    private cartSv: CartServiceService,
    private httpSv: HttpService
  ) {}

  transform(
    value: any[],
    points: any,
    season,
    test,
    tax = true
  ): Promise<number> {
    return new Promise(async (resolve) => {
      if (!value.length) resolve(0);
      const packingTax = await this.httpSv.itemIndex('PackingTax', '', {
        pag: 1,
      });
      const data = packingTax?.data?.data[0];
      const amountTax = tax ? data?.tax || 0 : 0;

      if (!season) {
        setTimeout(async () => {
          const commission = value.reduce((acc, next) => {
            if (!next.isChecked) return (acc += 0);
            let commissionProduct =
              next.commission || next.product?.commission || 0;
            const quantity = next.quantity || next.product?.quantity;
            const productPrice = next.price || next.product?.price;
            const price = (productPrice - commissionProduct) * quantity;
            return (acc += price);
          }, 0);
          resolve(commission + amountTax || 0);
          return;
        });
      }
      const limitOrder = season?.init;
      const limitOrderToDate = new Date(limitOrder).getTime();
      const actualDate = new Date().getTime();
      const diffDayMs = actualDate - limitOrderToDate;
      const diffDay = diffDayMs / (1000 * 60 * 60 * 24);

      setTimeout(async () => {
        const totalPrice = value.reduce((acc, next) => {
          if (!next.isChecked) return (acc += 0);
          const quantity = next.quantity || next.product?.quantity;
          let commission = next.commission || next.product?.commission || 0;
          const productPrice = next.price || next.product?.price;
          let price = productPrice * quantity - commission * quantity;
          const increase = this.comprobeDate(diffDay, points);
          if (increase && next.product.commission != 0 && this.cartSv.canBuy) {
            price =
              next.price * quantity - (next.price * increase * quantity) / 100;
          }
          return (acc += price);
        }, 0);
        resolve(totalPrice + amountTax || 0);
      });
    });
  }

  comprobeDate(actualDay, pointsParam) {
    if (
      actualDay >= pointsParam?.initCommission &&
      actualDay <= pointsParam?.daysCommission
    ) {
      return pointsParam?.increaseCommission;
    } else if (
      actualDay >= pointsParam?.daysCommission &&
      actualDay <= pointsParam?.endCommission
    ) {
      return pointsParam?.endIncreaseCommission;
    } else {
      return false;
    }
  }
}
