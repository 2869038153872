import { Component, OnInit, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { StripeService } from 'src/app/services/stripe.service';
import { UiServices } from 'src/app/services/ui-services';
import { environment } from 'src/environments/environment';
declare var Stripe;

@Component({
  selector: 'app-stripe',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss'],
})
export class StripeComponent implements OnInit, OnDestroy{
  @Output() sendStripeData = new EventEmitter();
  @Input() token = '';
  public stripe = undefined;
  public card: any;
  private _subscription = undefined;

  constructor(
    private stripeSv: StripeService,
    private uiSv: UiServices
  ) { }

  ngOnInit() {
    this._subscription = this.stripeSv.sendData.subscribe(() => {
      this.createSource();
    })
    this.setupStripe();
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
  }

  setupStripe() {
    this.stripe = Stripe(environment.stripePublishKey);
    let elements = this.stripe.elements();
    const style = {
      base: {
        color: '#32325d',
        lineHeight: '24px',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    this.card = elements.create('card', { style: style });
    this.card.mount('#card-element');

    this.card.addEventListener('change', event => {
      const displayError = document.getElementById('card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });
   
  }

  async createSource(){
    await this.uiSv.showLoading();
    this.stripe.createSource(this.card).then(async result => {
      if (result.error) {
        const errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        this.payWithStripe(result.source.id);
      }
      await this.uiSv.loading.dismiss();
    });
  }

  payWithStripe(token){
    const data = new FormData();
    data.set('token', token);
    this.sendStripeData.emit(data);
  }
}
