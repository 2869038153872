import { Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Notifications } from '../interfaces/interfaces';
import { HttpService } from './http.service';
import { LoginService } from './login.service';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {
  private _subjectActions = [
    {
      subject: 'Nueva promocion',
      url: 'product-detail'
    },
    {
      subject: 'Nuevo producto',
      url: 'product-detail'
    },
    {
      subject: 'Nuevo pedido',
      url: 'admin/paylist'
    },
    {
      subject: 'Producto Agotado',
      url: 'admin/products'
    },
    {
      subject: 'Tienes pedidos por verificar',
      url: 'admin/paylist'
    },
    {
      subject: 'Transaccion realizada',
      url: 'wallet'
    },
    {
      subject: 'Nuevo registro de Mayorista',
      url: 'admin/users'
    },
    {
      subject: 'Nuevo registro de Afiliado',
      url: 'admin/users'
    },
    {
      subject: 'Nuevo comentario',
      urlGallery: 'gallery',
      urlProduct: 'product-detail' 
    }
  ]
  private _notifications: Notifications[] = [];
  private _count = 0;
  public page = 1;
  public totalPages = 1;
  
  get notifications(){
    return this._notifications;
  }

  set notifications(value){
    this._notifications = value;
  }

  
  get count(){
    return this._count
  };

  set count(value){
    this._count = value;
  }

  constructor(
    private httpSv: HttpService,
    private navCtrl: NavController,
    private loginSv: LoginService
  ) {
  }

  subscribeLoginEvents(): void {
    this.loginSv.emitLogin.subscribe(() => {
      this.totalNotifications();
    })
    this.loginSv.emitLogout.subscribe(x => {
      this.totalNotifications();
      this.notifications = [];
    })
  }

  async totalNotifications(){
    try{
      const res = await this.httpSv.itemIndex('notification/count');
      this.count = res?.data || 0;
    }catch(err){
      console.error(err);

    }
  }

  async getNotifications(notView = ''){
    try{
      if(this.page <= this.totalPages){
      const param = {
        pag: this.page,
        notView
      }
      const res = await this.httpSv.itemIndex('notification', '', param);
      this.totalPages = res.data.meta.last_page;
        const notificationsData: Notifications[] = res.data.data;
        notificationsData.map(item => {
          item.data = JSON.parse(item.data);
        })
        this.notifications.push(...notificationsData);
        this.page++;
        return true;
      }else{
        return false;
      }
    }catch(err){
      console.error(err);
    }
  }

  async changeType(type){
    this.notifications = [];
    this.page = 1;
    // await this.getNotifications(type);
  }

  async markAllAsRead(){
    await this.httpSv.itemAction({}, 'notification', 'markAllAsRead');
    await this.totalNotifications();
    this.notifications = [];
    await this.getNotifications();
  }

  async goToNotification(notification: Notifications){
    try{
      const subject = this._subjectActions
        .find(item => item.subject.toLowerCase() === notification.subject.toLowerCase());
      this.marAsSeen(notification.id);
      if(subject.subject === 'Nuevo pedido' || subject.subject === 'Producto Agotado'){
        let queryParams: any = {};
        if(notification.data?.order?.id){
          queryParams.order = notification.data.order.id;
        }else if(notification.data?.product?.id){
          queryParams.product = notification.data.product.id;
        }
        await this.navCtrl.navigateForward(subject.url, {queryParams});
      }else if(subject.subject === 'Nuevo comentario'){
        const notifyData = notification.data;
        const url = notifyData.galleryId ? subject.urlGallery : subject.urlProduct;
        const id = notifyData.galleryId ? notifyData.galleryId : notifyData.productId;
        await this.navCtrl.navigateForward(`${url}/${id}`);
      }else if(subject.subject === 'Nueva promocion' || subject.subject === 'Nuevo producto'){
        await this.navCtrl.navigateForward(`${subject.url}/${notification.data.id}`);
      }else{
        await this.navCtrl.navigateForward(`${subject.url}`);
      }
      this.totalNotifications();
    }catch(err){
      console.error(err);
    }
  }

  async marAsSeen(id){
    await this.httpSv.getProduct(id, 'notification'); //marcar view
    return true;
  }
}
