import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-skeleton-detail',
  templateUrl: './skeleton-detail.component.html',
  styleUrls: ['./skeleton-detail.component.scss'],
})
export class SkeletonDetailComponent implements OnInit {
  @Input() product: any = undefined;
  
  constructor() { }

  ngOnInit() {}

}
