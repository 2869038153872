import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { HttpService } from 'src/app/services/http.service';
import { UiServices } from 'src/app/services/ui-services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-show-product-client',
  templateUrl: './show-product-client.component.html',
  styleUrls: ['./show-product-client.component.scss'],
})
export class ShowProductClientComponent implements OnInit {
  @Input() idProduct: any;
  product: any;
  urlImg: string = environment.uriProduct;
  carritoIcon: string = './assets/icon/union17-blanco.svg';
  cantidad: number = 0;
  savedQuantity: number = 0;
  thumbnailImage: string = '';
  priceProduct: number = 0;
  constructor(
    private httpSv: HttpService,
    public modalController: ModalController,
    private uiSv: UiServices,
    private translateSv: TranslateService
  ) {

  }

  async ngOnInit() {
    try{
      this.product = await this.httpSv.getProduct(this.idProduct);
      this.product?.media.map(img => {
        img.url = `${this.urlImg}${img.file}`;
      })
      // const value = await this.cartService.findValue(this.idProduct);
      this.thumbnailImage = `${this.urlImg}${this.product?.media[0]?.file}`;
      // if(value) {
      //   this.cantidad = value.cantidad;
      //   this.savedQuantity = this.cantidad;
      //   this.priceProduct = value.price;
      // }
    }catch(err){
      console.error(err);
    }
  }

  async countProducts(op){
    if(this.product?.existence === null || (this.cantidad < this.product?.existence) || op === 'rest'){
      // const { cantidad, priceProduct } = await this.cartService.countProducts(op, this.cantidad, this.priceProduct, this.product.price);
      // this.cantidad = cantidad;
      // this.priceProduct = priceProduct;
    }else{
      const translate = this.translateSv.instant('NO_HAY_CANTIDAD_DISPONIBLE');
     this.uiSv.showToast(translate);
    }
  }
  
  addCart(){
    // this.cartService.addCart(this.product.id, this.cantidad, this.product.price, this.thumbnailImage, this.product.description, this.priceProduct, this.product.name);
    this.savedQuantity = this.cantidad;
    this.modalController.dismiss(this.savedQuantity);
  }

}