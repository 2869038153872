import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { IonSlides, NavController } from '@ionic/angular';
import { ParamServicesService } from 'src/app/services/param-services.service';

@Component({
  selector: 'app-catalogue-home',
  templateUrl: './catalogue-home.component.html',
  styleUrls: ['./catalogue-home.component.scss'],
})
export class CatalogueHomeComponent implements OnInit, AfterViewInit {
  @ViewChild('sliderCategories') slider: IonSlides;
  @Input() data: any;
  @Input() catalogues: any[];
  public type = '';
  public customStyle = undefined;
  public reachedEnd = false;
  public isStart = true;
  public slideOpts: any = {
    spaceBetween: 15,
    breakpoints: {
      320: {
        slidesPerView: 2.1,
        spaceBetween: 0,
      },
      420: {
        slidesPerView: 3.1,
        // spaceBetween: 20,
      },
      700: {
        slidesPerView: 3,
        // spaceBetween: 20,
      },
      1270: {
        slidesPerView: 4,
        spaceBetween: 0,
      },
    }
  }
  constructor(
    private navCtrl: NavController,
    public paramSv: ParamServicesService
  ) { }

  ngOnInit() {
    this.setBreakpoints();
    const param = this.paramSv.emitImagesObs.subscribe((item: any) => {
      if(item.name === 'catalogueStyle'){
        this.setBreakpoints();
        param.unsubscribe();
      }else if(item === 'finish'){
        param.unsubscribe();
      }
    })
  }

  ngAfterViewInit() {
    setTimeout(async() => {
      this.reachedEnd = await this.slider.isEnd();
    })
  }

  async changedSlide(ev){
    this.reachedEnd = ev.el.dom7ElementDataStorage.swiper.isEnd;
    if(!this.reachedEnd || this.reachedEnd){
      this.isStart = ev.el.dom7ElementDataStorage.swiper.isBeginning;
    }
  }

  trackByItems(index, item){
    return item.id
  }

  setBreakpoints(){
    this.type = this.paramSv.typeCatalogue?.type;
    this.customStyle = this.paramSv.typeCatalogue?.customStyle;
    if(this.paramSv.typeCatalogue?.type === 'round'){
      const breakpoints = {
        320: {
          slidesPerView: 2.1,
        },
        520: {
          slidesPerView: 5.1,
        },
        950: {
          slidesPerView: 6,
        },
        1024: {
          slidesPerView: 7,
        },
        1200: {
          slidesPerView: 8,
        }
      }
      this.slideOpts.breakpoints = breakpoints;
    }
  }

  slideTo(pos?){
    (pos === 'next') ? this.slider.slideNext() : this.slider.slidePrev();
  }

  goCatalogue(id){
    this.navCtrl.navigateForward(`catalogue/${id}`);
  }
}
